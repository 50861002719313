import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { Button, message, Space } from 'antd';
import { setToken } from "../axios";
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { setLogout,setLogin,toggleLoader, getRegisterUser, getNewCase, setSingleCase, getVerifyCode, getUserHaveEmail, getUserForget, setAllUserCase, setProfile, setCaseHistory, setAllCompleteUserCase, getChangeLanguage, getNotice } from '../actions/Auth';

/*========== ASSESSMENT FUNCTIONS =============*/

export function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/loginUser', payload });
  if (error) {
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    localStorage.setItem('token',response["data"]["body"]["token"]);
    setToken(response["data"]["body"]["token"]);
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "user") {
      message.error({ type: 'error', content: "Can't login through User account "});
      return;
    }
    yield put(getChangeLanguage(decoded['languageType']));
    yield put(toggleLoader({status:false}));
    yield put(setLogin(true));
    message.success({ type: 'success', content:response['data']['message']});
  }
};
function* logout() {
  yield put(setLogout(""));
};

export function* setRegisterUser({ payload }) {
  const { error, response } = yield call(postCall, { path: "/registerUser", payload });
  if (error){
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getRegisterUser(response['data']['body']))
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setVerifyCode({ payload }) {
  const { error, response } = yield call(postCall, { path: "/codeVerify", payload });
  if (error){
    // yield put(getVerifyCode(response['data']['body']['status']))
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getVerifyCode(response['data']['body']['status']))
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setChangeLanguage({ payload }) {
  const { error, response } = yield call(postCall, { path: "/changeLanguage", payload });
  if (error){
    // yield put(getVerifyCode(response['data']['body']['status']))
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getChangeLanguage(response['data']['body']['languageType']))
    // message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setUserEmail({ payload }) {
  const { error, response } = yield call(postCall, { path: "/checkEmail", payload });
  if (error){
    // yield put(getUserHaveEmail(response['data']['body']['status']))
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(getUserHaveEmail(response['data']['body']['status']))
    yield put(toggleLoader({status:false}))
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setUserForget({ payload }) {
  const { error, response } = yield call(postCall, { path: "/forget", payload });
  if (error){
    // yield put(getUserForget(response['data']['body']['status']))
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(getUserForget(response['data']['body']['status']))
    yield put(toggleLoader({status:false}))
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setNewCase ({payload}) {
  const { error, response } = yield call(postCall, { path: "/newCase", payload });
  if (error){
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getNewCase(response['data']['body']))
    message.success({ type: 'success', content:response['data']['message']});
  }
}

export function* updateSingleCase ({payload}) {
  const { error, response } = yield call(postCall, { path: "/updateCase", payload });
  if (error){
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setSingleCase(response['data']['body']))
    message.success({ type: 'success', content:response['data']['message']});
  }
}

export function* getAllCases({payload}) {
  const { error, response } = yield call(getCall,"/getAllCases/"+payload['newDate']);
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});yield put(getNewCase([]));}
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getNewCase(response['data']['body']));
  }
};

export function* setNextAction({payload}) {
  const { error, response } = yield call(postCall, { path: "/updateNextAction", payload });
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});  yield put(toggleLoader({status:false})) }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getNewCase(response['data']['body']));
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setCaseComplete({payload}) {
  const { error, response } = yield call(postCall, { path: "/caseComplete", payload });
  if (error) {
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setAllUserCase(response['data']['body']));
    // message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setCaseNotComplete({payload}) {
  const { error, response } = yield call(postCall, { path: "/caseNotComplete", payload });
  if (error) {
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setAllCompleteUserCase(response['data']['body']));
    // message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setNextDate({payload}) {
  const { error, response } = yield call(postCall, { path: "/updateNextDate", payload });
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});  yield put(toggleLoader({status:false})) }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getNewCase(response['data']['body']));
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* setNextDateFilter({payload}) {
  const { error, response } = yield call(postCall, { path: "/updateNextDateFilter", payload });
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});  yield put(toggleLoader({status:false})) }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setAllUserCase(response['data']['body']));
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* getSingleCase({payload}) {
  const { error, response } = yield call(getCall,`/singleCase/${payload['id']}`);
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});yield put(getNewCase([]));}
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setSingleCase(response['data']['body']));
  }
};

export function* updateCase({payload}) {
  const { error, response } = yield call(getCall,`/updateCase/${payload['id']}`);
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});yield put(getNewCase([]));}
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setSingleCase(response['data']['body']));
  }
};

export function* getAllUserCase() {
  const { error, response } = yield call(getCall,`/getAllUserCase`);
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});yield put(setAllUserCase([]));}
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setAllUserCase(response['data']['body']));
  }
};

export function* deleteCase({payload}) {
  const { error, response } = yield call(postCall, { path: "/deleteCase", payload });
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});yield put(setAllUserCase([]));}
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setAllUserCase(response['data']['body']));
  }
};

export function* getUserProfile() {
  const { error, response } = yield call(getCall,`/getUser`);
  if (error) {
    // message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setProfile(response['data']['body']));
    yield put(getChangeLanguage(response['data']['body']['languageType']));
  }
};

export function* getProfile ({payload}) {
  const { error, response } = yield call(postCall, { path: "/updateProfile", payload });
  if (error){
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setProfile(response['data']['body']));
    message.success({ type: 'success', content:response['data']['message']});
  }
}

export function* getCaseHistory ({payload}) {
  const { error, response } = yield call(postCall, { path: "/getCaseHistory", payload });
  if (error){
    yield put(toggleLoader({status:false}))
    yield put(setCaseHistory([]));
    // message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setCaseHistory(response['data']['body']));
    // message.success({ type: 'success', content:response['data']['message']});
  }
}

export function* getAllCompleteCases ({payload}) {
  const { error, response } = yield call(getCall,`/getAllUserCompleteCase`);
  if (error){
    yield put(toggleLoader({status:false}))
    yield put(setAllCompleteUserCase([]));
    // message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setAllCompleteUserCase(response['data']['body']));
    // message.success({ type: 'success', content:response['data']['message']});
  }
}

export function* deleteCaseHistory ({payload}) {
  const { error, response } = yield call(postCall, { path: `/deleteHistory`, payload });
  if (error){
    yield put(toggleLoader({status:false}))
    message.error({ type: 'error', content: error['response']['data']['message']});
  }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setCaseHistory(response['data']['body']))
    message.success({ type: 'success', content:response['data']['message']});
  }
}

export function* setNotice({payload}) {
  const { error, response } = yield call(postCall, { path: "/addToNotice", payload });
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});  yield put(toggleLoader({status:false})) }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(setAllUserCase(response['data']['body']));
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* removeNotice({payload}) {
  const { error, response } = yield call(postCall, { path: "/deleteNotice", payload });
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});  yield put(toggleLoader({status:false})) }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getNotice(response['data']['body']));
    message.success({ type: 'success', content:response['data']['message']});
  }
};

export function* getApiNotice({payload}) {
  const { error, response } = yield call(getCall,`/getAllNotice`);
  if (error) {message.error({ type: 'error', content: error['response']['data']['message']});  yield put(toggleLoader({status:false})) }
  else if (response) {
    yield put(toggleLoader({status:false}))
    yield put(getNotice(response['data']['body']));
    message.success({ type: 'success', content:response['data']['message']});
  }
};


// setNotice, getApiNotice, removeNotice

function* actionWatcher() {
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401)  EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
