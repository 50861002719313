import React from "react";

const whatsappshare = "/images/whatsapp-share.png";


const WhatsAppButton = (props) => {

  const formattedMessage = Object.keys(props.data)
    .map(key => `${key}: ${props.data[key]}`)
    .join("\n");

  const fullMessage = `Here's the case detail:\n\n${formattedMessage}`;

  const encodedMessage = encodeURIComponent(fullMessage);
  const whatsappURL = `https://api.whatsapp.com/send?phone=${props.phoneno}&text=${encodedMessage}`;

  return (
    <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
     <img src={whatsappshare} width={30} alt="WhatsApp Share"/>
    </a>
  );
};

export default WhatsAppButton;
