import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { connect } from "react-redux";
import {Form, Button, Modal} from 'react-bootstrap';
import { toggleLoader, login, getAllCase,getUserProfile, setNextAction, setNextDate } from "../store/actions/Auth";
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.css';

function CustomCalendar(props) {
    // State to manage selected dates and their colors
    const [calendarVisible, setCalendarVisible] = useState(false);
    const [mark, setMark] = useState([]);
    const calendarRef = useRef(null);

    useEffect(()=>{
      if(props.getNewCase && props.getNewCase.previousCases && props.getNewCase.previousCases.length > 0){
        setMark(props.getNewCase.previousCases)
      }
    },[props.getNewCase.previousCases]);

    useEffect(()=>{
      if(props.isLogin){
        props.toggleLoader({message:"Load cases...", status:true});
        let newDate = new Date();
        // newDate = new Date(newDate.getTime() - (2 * 24 * 60 * 60 * 1000))
        props.getUserProfile();
        props.getAllCase({newDate});
      }
    },[props.isLogin,props.currentLanguage]);

    const toggleCalendar = () => {
        setCalendarVisible(!calendarVisible);
    };

    const tileClassName = ({ date }) => {
      const formattedDate = moment(date).format("DD-MM-YYYY");
      if (mark.includes(formattedDate)) {
        return 'highlight';
      }else{
        return 'nohighlight';
      }
    }

    const setDate = (date) => {
        props.toggleLoader({message:"Load cases...", status:true});
        let newDate = new Date(date);
        props.setCurrentDate(date);
        newDate.setDate(newDate.getDate() + 1);
        props.getAllCase({newDate});
    }  
    
    // Add an event listener to handle clicks outside the calendar
    useEffect(() => {
        const handleOutsideClick = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setCalendarVisible(false);
        }
        };

        // Attach the event listener when the calendar is visible
        if (calendarVisible) {
        window.addEventListener('click', handleOutsideClick);
        }

        // Remove the event listener when the component unmounts or when the calendar is hidden
        return () => {
        window.removeEventListener('click', handleOutsideClick);
        };
    }, [calendarVisible]);
  
    const handlePrint = () => {
        window.print();
      };
    
    const handleDownload = () => {
        const input = document.getElementById('printableArea');
        const options = {
          filename: 'downloaded_page.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
        };
        
        // Add a function to hide the "non-printable" class
        function hideNonPrintableElements() {
          const nonPrintableElements = document.querySelectorAll('.non-printable');
          nonPrintableElements.forEach((element) => {
            element.style.display = 'none';
          });
        }
        
        // Before generating the PDF, hide the non-printable elements
        hideNonPrintableElements();
        html2pdf().set(options).from(input).save().outputPdf()
        .then(() => {
          // After generating the PDF, restore the visibility of non-printable elements
          const nonPrintableElements = document.querySelectorAll('.non-printable');
          nonPrintableElements.forEach((element) => {
            element.style.display = '';
          });
        });
    };  

    return (
        <div className='divMargin' ref={calendarRef}>
        <Button onClick={toggleCalendar} style={{margin:10}}>Calendar</Button>
        <Button style={{margin:10}} onClick={handlePrint} > Print</Button>
        <Button style={{margin:10}} onClick={handleDownload} >Download </Button>
        {calendarVisible && (
            <div style={{position:"absolute"}}>
                <Calendar
                    style={{ height: 500 }}
                    onChange={setDate}
                    tileClassName={tileClassName}
                    // minDate={new Date()}
                />
            </div>
        )}
        </div>
    );
  }
  
const mapDispatchToProps = { toggleLoader, login,getAllCase, getUserProfile, setNextAction, setNextDate };

const mapStateToProps = ({ Auth }) => {
  let { isLogin,getNewCase,profile,currentLanguage } = Auth;
  return { isLogin,getNewCase,profile,currentLanguage };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCalendar);