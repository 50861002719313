import React, { useState, forwardRef } from "react";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link , Navigate} from "react-router-dom";
import { Container, Form, Button } from 'react-bootstrap';
import {  message, Space } from 'antd';
import DatePicker from "react-datepicker";
import { toggleLoader , setNewCase} from "../store/actions/Auth";
import './UrduForm.css';
import "react-datepicker/dist/react-datepicker.css";

const Signin = (props) => {

  let [caseNo,setCaseNo] = useState("");
  let [currentDate,setCurrentDate] = useState(new Date());
  let [caseYearPolicestation,setCaseYearPolicestation] = useState("");
  let [criminal,setCriminal] = useState("");
  let [titleB,setTitleB] = useState("");
  let [titleA,setTitleA] = useState("");
  let [addalat,setAddalat] = useState("");
  let [lastAction,setLastAction] = useState("");
  let [nextDate,setnextDate] = useState(new Date());
  let [nextAction,setnextAction] = useState("");
  let [clientname,setclientname] = useState("");
  let [phoneno,setphoneno] = useState("");
  let [totalFee,settotalFee] = useState(0);
  let [totalPay,settotalPay] = useState(0);
  let [outstandingFee,setoutstandingFee] = useState(0);
  let [caseURL,setCaseURL] = useState("");
  let [clender,setClender] = useState(false);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button className="form-control urdu-input" lang="ur" dir="rtl" onClick={onClick} ref={ref}>
      {value}
    </Button>
  ));
  

  const urduAlphabetMapping = {
    q: 'ق', w: 'و', e: 'ع', r: 'ر', t: 'ت', y: 'ے', u: 'ء', i: 'ی',
    p: 'پ', a: 'ا', s: 'س', d: 'د', f: 'ف', g: 'گ', h: 'ح', j: 'ج',
    k: 'ک', l: 'ل', z: 'ز', x: 'ش', c: 'چ', v: 'ط', b: 'ب', n: 'ن',
    m: 'م', Q: ' ْ', W: ' ٔ', E: ' ٰ', R: 'ڑ', T: 'ٹ', Y: ' َ', U: 'ئ',
    I: ' ِ', O: 'ۃ', P: ' ُ', A: 'آ', S: 'ص', D: 'ڈ', F: 'أ', G: 'غ', 
    H: 'ھ', J: 'ض', K: 'خ', L: 'ٖ', Z: 'ذ', X: 'ژ', C: 'ث', V: 'ظ', 
    B: ' ً', N: 'ں', M:' ّ', o: 'ہ'
  };  

  const submitForm = (e) => {
    e.preventDefault();
    if(!caseNo) return message.error({ type: 'error', content: "Please Add Case No"});
    props.toggleLoader({status:true,message:"Case Saving..."});
    props.setNewCase({caseNo,caseURL,currentDate,caseYearPolicestation,criminal,addalat,lastAction,nextDate,nextAction,clientname,phoneno,totalFee,totalPay,outstandingFee, titleA, titleB})
  }

  React.useEffect(()=>{
    if(props.getNewCase.length > 0) {
      setCaseNo("")
      setCurrentDate("")
      setCaseYearPolicestation("")
      setCriminal("")
      setTitleB("")
      setTitleA("")
      setAddalat("")
      setLastAction("")
      setnextDate("")
      setnextAction("")
      setclientname("")
      setphoneno("")
      setCaseURL("");
      settotalFee(0)
      settotalPay(0)
      setoutstandingFee(0)
    }
  },[props.getNewCase])

  const totalPayAmount = (e) =>{
    totalPay = parseInt(totalFee) - parseInt(e.target.value)
    settotalPay(e.target.value);
    setoutstandingFee(totalPay)
  }

  const totalFeeAmount = (e) => {
    totalPay = parseInt(e.target.value) - parseInt(totalPay)
    settotalFee(e.target.value);
    settotalPay(totalPay);
    setoutstandingFee(totalPay)
  }
  // let token = localStorage.getItem('token');
  if(!props.isLogin) return <Navigate to="/" />

  return (
    <>
      <section className="wrapper">
        <div className="container urdu-form font-tables">
         <h2 className="text-center"> New Case</h2>
          {props.currentLanguage == "urdu" ?
          <Form onSubmit={submitForm}>
            <Form.Group className="formName">
              <Form.Label className="urdu-label">کیس نمبر</Form.Label>
              <Form.Control  className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" کیس نمبر" onChange={e=>setCaseNo(e.target.value)} value={caseNo}/>
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label">موجودہ تاریخ</Form.Label>
              {/* <Form.Control type="text" disabled className="form-control urdu-input" lang="ur" dir="rtl" placeholder="موجودہ تاریخ" value={currentDate}/> */}
              {/* <Form.Control type="date" disabled className="form-control urdu-input" lang="ur" dir="rtl" placeholder="موجودہ تاریخ" onChange={e=>setCurrentDate(e.target.value)} value={currentDate}/> */}
              {/* <CustomCalendarCustem setCurrentDate={setCurrentDate} setClender={clender} onChangeClender={(e)=>console.log("***************** setClender",e)}/> */}
              <DatePicker 
                className="form-control urdu-input" 
                dateFormat="dd/MM/yyyy" 
                lang="ur" dir="rtl" placeholder="موجودہ تاریخ" 
                selected={currentDate} 
                onChange={(date) => setCurrentDate(date)} 
              />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label">مقدمہ/ سال / پولیس اسٹیشن</Form.Label>
              <Form.Control  as="textarea" rows={5}  className="form-control urdu-input" lang="ur" dir="rtl" placeholder="مقدمہ/ سال / پولیس اسٹیشن" onChange={e=> setCaseYearPolicestation(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={caseYearPolicestation}/>
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label">ویب لنک</Form.Label>
              <Form.Control className="form-control urdu-input"  placeholder="https://dsjlahore.punjab.gov.pk/casedetail/2c0" onChange={e=>setCaseURL(e.target.value)} value={caseURL} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >  نوعیت/جرم</Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="مجرم" onChange={e=>setCriminal(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={criminal}/>
            </Form.Group>

            <div class="row" style={{marginTop:50}}>
                <div class="col-5">
                  <Form.Group className="formEmail">
                    {/* <Form.Label className="urdu-label" > عنوان  </Form.Label> */}
                    <Form.Control style={{marginTop:40}} className="form-control urdu-input" lang="ur" dir="rtl" placeholder="B" onChange={e=>setTitleB(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={titleB} />
                  </Form.Group>
                </div>
                <div class="col-2">
                   <Form.Group style={{marginTop:55}}>
                    <h4> بنام </h4>
                  </Form.Group>
                </div>
                <div class="col-5">
                  <Form.Group className="formEmail">
                    <Form.Label className="urdu-label" > عنوان  </Form.Label>
                    <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="A" onChange={e=>setTitleA(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={titleA} />
                  </Form.Group>
                </div>
            </div>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > عدالت  </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="عدالت" onChange={e=>setAddalat(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={addalat} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >امروز کاروائی  </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="امروز کاروائی" onChange={e=>setLastAction(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={lastAction} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >  آئندہ تاریخ پیشی  </Form.Label>
              {/* <Form.Control type="date" className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" آئندہتاریخ پیشی" onChange={e=>setnextDate(e.target.value)} value={nextDate} /> */}
              <DatePicker 
                className="form-control urdu-input" 
                dateFormat="dd/MM/yyyy" 
                lang="ur" dir="rtl" placeholder=" آئندہ تاریخ پیشی " 
                selected={nextDate} 
                onChange={(date) => setnextDate(date)} 
              />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > آئندہ کاروائی	  </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" آئندہ کاروائی	" onChange={e=>setnextAction(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={nextAction} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > کلائنٹ کا نام  </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" کلائنٹ کا نام" onChange={e=>setclientname(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={clientname} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > فون نمبر  </Form.Label>
              <Form.Control type="tel" className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" فون نمبر" onChange={e=>setphoneno(e.target.value)} value={phoneno} />
            </Form.Group>


            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > کل فیس  </Form.Label>
              <Form.Control type="number" className="form-control urdu-input" lang="ur" dir="rtl" placeholder="کل فیس" onChange={e=>totalFeeAmount(e)} value={totalFee}  />
            </Form.Group>


            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > فیس جو ادا کی گی  </Form.Label>
              <Form.Control type="number" className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" فیس جو ادا کی گی" onChange={e=>totalPayAmount(e)} value={totalPay} />
            </Form.Group>


            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > بقیہ فیس </Form.Label>
              <Form.Control type="number" className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" بقیہ فیس" onChange={e=>setoutstandingFee(e.target.value)} value={outstandingFee} disabled={true} />
            </Form.Group>
            <div className="text-center">
                <Button  className="common-btn" style={{marginTop:30}} type="submit">
                  Submit
                </Button>
            </div>
           
          </Form>
          :
          <Form onSubmit={submitForm}>
          <Form.Group className="formName" style={{textAlign:"left"}}>
            <Form.Label >Case No.</Form.Label>
            <Form.Control  className="form-control" placeholder=" Case No." onChange={e=>setCaseNo(e.target.value)} value={caseNo}/>
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label>Current Date.</Form.Label>
            {/* <Form.Control type="date" className="form-control" placeholder="Current Date." onChange={e=>setCurrentDate(e.target.value)} value={currentDate}/> */}
            <DatePicker 
                className="form-control" 
                dateFormat="dd/MM/yyyy" 
                placeholder="Current Date." 
                selected={currentDate} 
                onChange={(date) => setCurrentDate(date)} 
              />
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label>Case/Police Station.</Form.Label>
            <Form.Control  as="textarea" rows={5}  className="form-control" placeholder="Case/Police Station." onChange={e=> setCaseYearPolicestation(e.target.value)} value={caseYearPolicestation}/>
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label > Web Link.</Form.Label>
            <Form.Control className="form-control" placeholder="https://dsjlahore.punjab.gov.pk/casedetail/2c0" onChange={e=>setCaseURL(e.target.value)} value={caseURL} />
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label >Offence/Case.</Form.Label>
            <Form.Control className="form-control" placeholder="Offence/Case." onChange={e=>setCriminal(e.target.value)} value={criminal}/>
          </Form.Group>

          <div class="row">
              <div class="col-5">
                <Form.Group className="formEmail" style={{textAlign:"left"}}>
                <Form.Label style={{textAlign:"left"}}> Title.  </Form.Label>
                  <Form.Control className="form-control" placeholder="A" onChange={e=>setTitleA(e.target.value)} value={titleA} />
                </Form.Group>
              </div>
              <div class="col-2">
                 <Form.Group style={{marginTop:55}}>
                  <h4> VS</h4>
                </Form.Group>
              </div>
              <div class="col-5">
                <Form.Group className="formEmail" style={{textAlign:"left"}}>
                  <Form.Control style={{marginTop:40}} className="form-control" placeholder="B" onChange={e=>setTitleB(e.target.value)} value={titleB} />
                </Form.Group>
              </div>
          </div>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label > Court Name.  </Form.Label>
            <Form.Control className="form-control" placeholder="Court Name" onChange={e=>setAddalat(e.target.value)} value={addalat} />
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label >Today Proceeding.  </Form.Label>
            <Form.Control className="form-control" placeholder="Today Proceeding." onChange={e=>setLastAction(e.target.value)} value={lastAction} />
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label >  Next date. </Form.Label>
            {/* <Form.Control type="date" className="form-control" placeholder="Next date." onChange={e=>setnextDate(e.target.value)} value={nextDate} /> */}
            <DatePicker 
                className="form-control" 
                dateFormat="dd/MM/yyyy" 
                placeholder="Next date." 
                selected={nextDate} 
                onChange={(date) => setnextDate(date)} 
              />
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label > Next Proceeding.  </Form.Label>
            <Form.Control className="form-control" placeholder=" Next Proceeding." onChange={e=>setnextAction(e.target.value)} value={nextAction} />
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label > Client Name.</Form.Label>
            <Form.Control className="form-control" placeholder="Client Name." onChange={e=>setclientname(e.target.value)} value={clientname} />
          </Form.Group>

          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label > Phone No. </Form.Label>
            <Form.Control type="tel" className="form-control" placeholder="Phone No." onChange={e=>setphoneno(e.target.value)} value={phoneno} />
          </Form.Group>


          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label >Total Fee. </Form.Label>
            <Form.Control type="number" className="form-control" placeholder="Total Fee." onChange={e=>totalFeeAmount(e)} value={totalFee}  />
          </Form.Group>


          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label >Fees to be paid. </Form.Label>
            <Form.Control type="number" className="form-control" placeholder=" Fees to be paid." onChange={e=>totalPayAmount(e)} value={totalPay} />
          </Form.Group>


          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label >Remaining Fees. </Form.Label>
            <Form.Control type="number" className="form-control" placeholder="Remaining Fees." onChange={e=>setoutstandingFee(e.target.value)} value={outstandingFee} disabled={true} />
          </Form.Group>
          <div className="text-center">
              <Button  className="common-btn" style={{marginTop:30}} type="submit">
                Submit
              </Button>
          </div>
         
        </Form>

          }

        </div>
       
      </section>
    </>
  );
};

const mapDispatchToProps = { toggleLoader, setNewCase };

const mapStateToProps = ({ Auth }) => {
  let { isLogin,getNewCase,currentLanguage } = Auth;
  return {isLogin,getNewCase,currentLanguage};
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
