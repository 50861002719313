import React, { useState,Fragment,useEffect } from "react";
import EventBus from "eventing-bus";
import ReactTable from "react-table-6";
import { connect } from "react-redux";
import { Link , Navigate} from "react-router-dom";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import moment from "moment";
import {Form, Button, Modal} from 'react-bootstrap';
import { toggleLoader, login, getAllCase,getUserProfile, setNextAction, setNextDate, setNotice } from "../store/actions/Auth";
import WhatsApp from "./WhatsappShare";
import ReactToPrint from 'react-to-print';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import CustomCalendar from './CustomCalendar';

import 'moment/locale/ur';
import "./index.css";
import "./UrduForm.css";
import 'react-table-6/react-table.css'
import 'bootstrap/dist/css/bootstrap.min.css';
 const lawyerDiary = "/lawyerdirary.png";
const Signin = (props) => {

const textareaRef = React.useRef(null);
 let [cases, setCases] = useState([]);
 let [currentDate, setCurrentDate] = useState(new Date())
 const [itemPerRow, setItemPerRow] = useState(30);
 const [next, setNext] = useState(itemPerRow);
 let [changeNextAction, setChangeNextAction] = useState();

  // useEffect(()=>{
  //   if(props.isLogin){
  //     props.toggleLoader({message:"Load today cases...", status:true});
  //     let newDate = new Date();
  //     // newDate = new Date(newDate.getTime() - (2 * 24 * 60 * 60 * 1000))
  //     props.getUserProfile();
  //     props.getAllCase({newDate});
  //   }
  // },[props.isLogin]);

  useEffect(()=>{
    setCases([])
    if(props.getNewCase && props.getNewCase.currentCases && props.getNewCase.currentCases.length > 0){ 
      setCases(props.getNewCase.currentCases);
      setChangeNextAction();
    }
  },[props.getNewCase.currentCases])

  useEffect(()=>{
    const textarea = textareaRef.current;
    if(textarea){
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  },[textareaRef.current])
  // let token = localStorage.getItem('token');
  if(!props.isLogin) return <Navigate to="/" />

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const input = document.getElementById('printableArea');
    const options = {
      filename: 'downloaded_page.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().set(options).from(input).save();
  };  

  const nextDate = ({index,nextDate}) =>{
    cases[index]['nextDate'] = nextDate;
    setCases([...cases])
    setChangeNextAction(index);
  }

  const nextAction = ({index,nextAction}) =>{
    cases[index]['nextAction'] = nextAction;
    setCases([...cases])
    setChangeNextAction(index);
  }

  const submitNextDate = ({_id,nextDate,nextAction}) => {
    let newDate = new Date();
    props.toggleLoader({message:"Update cases...", status:true});
    props.setNextDate({ nextDate,nextAction, _id,date:newDate})
  }

  const submitNextAction = ({_id,nextAction,nextDate}) => {
    let newDate = new Date();
    props.setNextAction({_id,nextAction,date:newDate,nextDate})
  }

  const urduAlphabetMapping = {
    q: 'ق', w: 'و', e: 'ع', r: 'ر', t: 'ت', y: 'ے', u: 'ء', i: 'ی',
    p: 'پ', a: 'ا', s: 'س', d: 'د', f: 'ف', g: 'گ', h: 'ح', j: 'ج',
    k: 'ک', l: 'ل', z: 'ز', x: 'ش', c: 'چ', v: 'ط', b: 'ب', n: 'ن',
    m: 'م', Q: ' ْ', W: ' ٔ', E: ' ٰ', R: 'ڑ', T: 'ٹ', Y: ' َ', U: 'ئ',
    I: ' ِ', O: 'ۃ', P: ' ُ', A: 'آ', S: 'ص', D: 'ڈ', F: 'أ', G: 'غ', 
    H: 'ھ', J: 'ض', K: 'خ', L: 'ٖ', Z: 'ذ', X: 'ژ', C: 'ث', V: 'ظ', 
    B: ' ً', N: 'ں', M:' ّ', o: 'ہ'
  };  

  const handleTextareaInput = (index,action,_id) => {
    nextAction({index,nextAction:action});
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleMoreImage = () => {
    setNext(next + itemPerRow);
};

const handleDateClick = (date) => {
  alert(`Date clicked: ${date}`);
  // Add your custom logic here when a date is clicked.
};

const addToNotic = (data) =>{
  let newData = Object.assign(data, {caseId:data['_id']})
  props.setNotice({...newData})
}

  return (
  // <div class="container">
  <div class="container-fluid">
  <div class="content-wrapper">
    <div class="row">
      <div class="cpadding">
                 
              <table class="table table-striped table-bordered print-table" id="printableArea" >
                <thead className="font-tables urdu-form">
                  <tr>
                    <th colspan="9" className="th" style={{backgroundColor:"white", border:0}}>
                    <div class="row">
                    <div class="col-2 offset-md-1">
                    <img src={lawyerDiary} width={100}/>
                    </div>
                    <div class="col-9">
                      <h3 className="text-center font-tables-headings" style={{padding:10}}> {props.profile?.name ? props.profile.name : ""}</h3>
                      {
                      props.currentLanguage == "urdu" ?  
                      <h4 className="text-center font-tables"><big> {props.profile?.phone ? props.profile.phone : "" } </big> <big>{props.profile?.address ? props.profile.address : ""}</big> </h4>
                      : 
                      <h4 className="text-center font-tables"> <big>{props.profile?.address ? props.profile.address : ""}</big> <big> {props.profile?.phone ? props.profile.phone : "" } </big></h4>
                      }
                     {
                     props.currentLanguage == "urdu" ?  <h5 className="text-center font-tables"><big>فہرست مقدمات مورخہ {moment(currentDate).locale('ur').format('YYYY-MM-DD')} بروز {moment(currentDate).locale('ur').format('dddd')}</big> </h5>
                     :
                     <h5 className="text-center font-tables"><big>List of Cases at {moment(currentDate).locale('en').format('YYYY-MM-DD')} {moment(currentDate).locale('en').format('dddd')}</big> </h5>
                     }
                    </div>
                  </div>
                    <div className="row non-printable" >
                      <div className="edit-add-title2 offset-md-9 col-3 text-end non-printable">
                      <div className="urdu-form">
                            <CustomCalendar setCurrentDate={setCurrentDate}/>
                      </div>
                      </div>
                    </div>
                    </th>
                  </tr>  

                  {props.currentLanguage == "urdu" ?
                  <tr>
                    <th class="urdu-font-14 text-center non-printable">نوٹس میں شامل کریں</th>
                    <th class="urdu-font-14 text-center">آئندہ کاروائی		</th>
                    <th class="urdu-font-14 text-center">آئندہ تاریخ پیشی</th>
                    <th class="urdu-font-14 text-center">امروز کاروائی</th>
                    <th class="urdu-font-14 text-center">عدالت</th>
                    <th class="urdu-font-14 text-center">عنوان	</th>
                    <th class="urdu-font-14 text-center">نوعیت/جرم</th>
                    <th class="urdu-font-14 text-center">مقدمہ/ سال / پولیس اسٹیشن</th>
                    <th class="urdu-font-14 text-center">سابقہ تاریخ پیشی</th>
                    <th class="urdu-font-14 text-center">کیس نمبر</th>
                    <th class="urdu-font-14 text-center">نمبرشمار</th>
                  </tr>
                   :
                  <tr>
                    <th class="urdu-font-14 text-center">No.</th>
                    <th class="urdu-font-14 text-center">Case No.</th>
                    <th class="urdu-font-14 text-center">Previous Date.</th>
                    <th class="urdu-font-14 text-center">Case/Police Station.</th>
                    <th class="urdu-font-14 text-center">Offence/Case.</th>
                    <th class="urdu-font-14 text-center">Case Title.</th>
                    <th class="urdu-font-14 text-center">Court Name.</th>
                    <th class="urdu-font-14 text-center">Today Proceeding.</th>
                    <th class="urdu-font-14 text-center">Next Proceeding.</th>
                    <th class="urdu-font-14 text-center">Next date.</th>
                    <th class="urdu-font-14 text-center non-printable">Add to Notice</th>
                  </tr>
                  }
                </thead>
                {props.currentLanguage == "urdu" ?
                <tbody className="urdu-form font-tables">
                {cases && cases.length > 0 && cases.slice(0, next).map((item,index)=>
                      <tr key={index}>
                        <td className="non-printable" style={{wordBreak:"break-word", width:150}}> <Button className="non-printable btn-success" onClick={()=>addToNotic(item)}>Add to notice</Button></td>
                        <td style={{wordBreak:"break-word", width:20}}><textarea 
                        className="expanding-textarea urdu-form non-printable"
                        ref={textareaRef}
                        style={{width:120, border:"0px"}} type="text" value={item["nextAction"]}  
                        onChange={e=>handleTextareaInput(index,e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''),item["_id"])} /></td>
                        {/* onChange={e=>nextAction({index,nextAction:e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''),_id:item["_id"]})}/></td> */}
                        <td style={{wordBreak:"break-word", width:20}}>
                        <span className="non-printable">
                        <DatePicker
                            className="custom-datepicker"
                            selected={item["nextDate"] ? new Date(item["nextDate"]) : ""}
                            dateFormat="dd.MM.yyyy" // Set the desired format
                            placeholderText="Select a date"
                            onChange={e=>nextDate({index,nextDate:e,_id:item["_id"]})}/> 
                            {changeNextAction == index  &&  <>
                              <Button className="non-printable btn-info" onClick={()=>submitNextDate({nextDate:item["nextDate"],nextAction:item["nextAction"],_id:item["_id"]})}>Submit</Button>
                              {/* <Button className="non-printable btn-success" onClick={()=>addToNotic(item)}>Add to notice</Button> */}
                            </>} 
                        </span>
                        </td>
                        <td style={{wordBreak:"break-word"}}>{item["lastAction"] ? item["lastAction"] : "-"}</td>
                        <td style={{wordBreak:"break-word"}}>{item["addalat"] ? item["addalat"] : "-"}</td>
                        <td style={{wordBreak:"break-word"}}>{ item["titleA"] ? item["titleA"] : "-" } بنام { item["titleB"] ? item["titleB"] : "-" }</td>
                        <td style={{wordBreak:"break-word"}}>{item["criminal"] ? item["criminal"] : "-"}</td>
                        <td style={{wordBreak:"break-word"}}>{item["caseYearPolicestation"] ? item["caseYearPolicestation"] : "-"} </td>
                        <td style={{wordBreak:"break-word",width:130}}>{item["currentDate"] ? moment(item["currentDate"]).format('DD.MM.YYYY'): "-"}</td>
                        <td style={{wordBreak:"break-word",width:130}}><Link to={"/viewcase/"+item['_id']}>{item["caseNo"] ? item["caseNo"] : "-"}</Link></td>
                        <td style={{wordBreak:"break-word"}}>{index + 1}</td>
                      </tr>
                    )}
               </tbody>
               :
               <tbody className="font-tables">
               {cases && cases.length > 0 && cases.slice(0, next).map((item,index)=>
                     <tr key={index}>
                       <td style={{wordBreak:"break-word",textAlign:"center"}}>{index + 1}</td>
                       <td style={{wordBreak:"break-word",textAlign:"left"}}> <Link to={"/viewcase/"+item['_id']}>{item["caseNo"] ? item["caseNo"] : "-"}</Link></td>
                       <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["currentDate"] ? moment(item["currentDate"]).format('DD.MM.YYYY'): "-"}</td>
                       <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["caseYearPolicestation"] ? item["caseYearPolicestation"] : "-"} </td>
                       <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["criminal"] ? item["criminal"] : "-"}</td>
                       <td style={{wordBreak:"break-word",textAlign:"left"}}>{ item["titleA"] ? item["titleA"] : "-" } VS { item["titleB"] ? item["titleB"] : "-" }</td>
                       <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["addalat"] ? item["addalat"] : "-"}</td>
                       <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["lastAction"] ? item["lastAction"] : "-"}</td>
                       <td style={{wordBreak:"break-word", width:20}}><textarea 
                       className="expanding-textarea non-printable"
                       ref={textareaRef}
                       style={{width:120, border:"0px"}} type="text" value={item["nextAction"]}  
                       onChange={e=>handleTextareaInput(index,e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''),item["_id"])} /></td>
                       <td style={{wordBreak:"break-word", width:20}}>
                       <span className="non-printable">
                       <DatePicker
                           className="custom-datepicker"
                           selected={item["nextDate"] ? new Date(item["nextDate"]) : ""}
                           dateFormat="dd.MM.yyyy" // Set the desired format
                           placeholderText="Select a date"
                           onChange={e=>nextDate({index,nextDate:e,_id:item["_id"]})}/> 
                           {changeNextAction == index  &&  <Button className="non-printable" onClick={()=>submitNextDate({nextDate:item["nextDate"],nextAction:item["nextAction"],_id:item["_id"]})}>Submit</Button>} 
                       </span>
                       </td>
                       <td className="non-printable" style={{wordBreak:"break-word",textAlign:"left"}}> <Button className="non-printable btn-success" onClick={()=>addToNotic(item)}>Add to notice</Button></td>
                     </tr>
                   )}
              </tbody>
                }
              </table>
                {next < cases?.length && (
                <div className="text-center non-printable">
                  <Button onClick={handleMoreImage}>Load More</Button>
                </div>
                )}
            </div>
      </div>
  </div>
  </div>
  );
};

const mapDispatchToProps = { toggleLoader, login,getAllCase, getUserProfile, setNextAction, setNextDate, setNotice};

const mapStateToProps = ({ Auth }) => {
  let { isLogin,getNewCase,profile,currentLanguage } = Auth;
  return { isLogin,getNewCase,profile,currentLanguage };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
