import React, { useState } from "react";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link , Navigate} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import { message, Space } from 'antd';
import { toggleLoader, login, getProfile } from "../store/actions/Auth";
import './UrduForm.css';

const lawyerDiary = "/lawyerdirary.png";

const UpdateProfile = (props) => {

  const [name,setName] = useState();
  const [phone,setPhone] = useState();
  const [address,setAddress] = useState();

  const submitForm = (e) => {
    e.preventDefault();
    props.toggleLoader({status:true,message:"Update profile..."});
    props.getProfile({name,phone,address})
  }

  const urduAlphabetMapping = {
    q: 'ق', w: 'و', e: 'ع', r: 'ر', t: 'ت', y: 'ے', u: 'ء', i: 'ی',
    p: 'پ', a: 'ا', s: 'س', d: 'د', f: 'ف', g: 'گ', h: 'ح', j: 'ج',
    k: 'ک', l: 'ل', z: 'ز', x: 'ش', c: 'چ', v: 'ط', b: 'ب', n: 'ن',
    m: 'م', Q: ' ْ', W: ' ٔ', E: ' ٰ', R: 'ڑ', T: 'ٹ', Y: ' َ', U: 'ئ',
    I: ' ِ', O: 'ۃ', P: ' ُ', A: 'آ', S: 'ص', D: 'ڈ', F: 'أ', G: 'غ', 
    H: 'ھ', J: 'ض', K: 'خ', L: 'ٖ', Z: 'ذ', X: 'ژ', C: 'ث', V: 'ظ', 
    B: ' ً', N: 'ں', M:' ّ', o: 'ہ'
  };  
  // let token = localStorage.getItem('token');
  if(!props.isLogin) return <Navigate to="/" />

  return (
    <>
      <section className="wrapper">
       
        <div className="container align-items-center justify-content-center vh-100">
        {/* <div className="container d-flex align-items-center justify-content-center vh-100"> */}
           <div className="row vertical-center urdu-form font-tables">
              <div  class="col">
                {props.currentLanguage == "urdu" ?
                <Form className="sign-in" onSubmit={(e)=>submitForm(e)}>
                  <h1>پروفائل کو اپ ڈیٹ کریں۔</h1>

                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className="form-control urdu-input" lang="ur" dir="rtl" 
                      placeholder="نام"
                      value={name}
                      onChange={e=>setName(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className="form-control urdu-input" lang="ur" dir="rtl" 
                      placeholder="دفتر کا پتہ"
                      value={address}
                      onChange={e=>setAddress(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className="form-control urdu-input" lang="ur" dir="rtl" 
                      placeholder="فون نمبر"
                      value={phone}
                      onChange={e=>setPhone(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))}
                    />
                  </Form.Group>
                  <Button type="submit" className="common-btn mb-3" onClick={(e)=>submitForm(e)}>
                    Update
                  </Button>
                </Form>
                :
                <Form className="sign-in" onSubmit={(e)=>submitForm(e)}>
                  <h1>Update Profile</h1>

                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className="form-control" 
                      placeholder="Name"
                      value={name}
                      onChange={e=>setName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className="form-control"
                      placeholder="Office Address"
                      value={address}
                      onChange={e=>setAddress(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className="form-control"
                      placeholder="Phone No"
                      value={phone}
                      onChange={e=>setPhone(e.target.value)}
                    />
                  </Form.Group>
                  <Button type="submit" className="common-btn mb-3" onClick={(e)=>submitForm(e)}>
                    Update
                  </Button>
                </Form>
              }
              </div>
           </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = { toggleLoader, login, getProfile };

const mapStateToProps = ({ Auth }) => {
  let { isLogin,currentLanguage } = Auth;
  return { isLogin,currentLanguage };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);
