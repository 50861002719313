import React, { useState } from "react";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link , useNavigate, Navigate} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import { message, Space } from 'antd';
import { toggleLoader, login, getRegisterUser, setUserHaveEmail, setUserForget } from "../store/actions/Auth";
const lawyerDiary = "/lawyerdirary.png";

const Signin = (props) => {

  const navigate = useNavigate();

  const [email,setEmail] = useState("");
  const [forgetActivity,setForgetActivity] = useState(false);
  const [password,setPassword] = useState("");
  const [conformPassword,setConformPassword] = useState("");
  const [verifyingCode, setVerifyingCode] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    if(!email) return message.error({ type: 'error', content: 'Please enter email'});
    props.toggleLoader({status:true,message:"user email verifying..."});
    props.setUserHaveEmail({email})
  }

  const submitForget = (e) => {
    e.preventDefault();
    if(!password) return message.error({ type: 'error', content: 'Please enter password'});
    if(!conformPassword) return message.error({ type: 'error', content: 'Please enter conform password'});
    if(!email) return message.error({ type: 'error', content: 'Please enter email'});
    if(!verifyingCode) return message.error({ type: 'error', content: 'Please enter code'});
    props.toggleLoader({status:true,message:"user forget password..."});
    props.setUserForget({email,verificationCode:verifyingCode,password})
  }

  React.useEffect(()=>{
    if(props.getUserHaveEmail == true) {
      setForgetActivity(true);
    }
  },[props.getUserHaveEmail]);

  React.useEffect(()=>{
    if(props.getUserForget == true) {
      navigate("/");
    }
  },[props.getUserForget]);

  // let token = localStorage.getItem('token');
  if(props.isLogin) return <Navigate to="/mainpage" />

  return (
    <>
      <section className="wrapper">
        <div className="container d-flex align-items-center justify-content-center vh-100">
          {!forgetActivity ?
            <Form className="sign-in" onSubmit={(e)=>submitForm(e)}>
              {/* <img src={lawyerDiary} width={200}/> */}
              <h1>Forgot Password</h1>

              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  value={email}
                  onChange={e=>setEmail(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" className="common-btn mb-3" onClick={(e)=>submitForm(e)}>
                Forgot
              </Button>
              <p>
                Go to signin page <Link to="/">Sign in</Link>
              </p>
            </Form>
            :
            <Form className="sign-in" onSubmit={(e)=>submitForget(e)}>
            {/* <img src={lawyerDiary} width={200}/> */}
            <h1>Submit Code & Password</h1>

            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Enter Code"
                value={verifyingCode}
                onChange={e=>setVerifyingCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                    type="password"
                    onChange={e=>setPassword(e.target.value)}
                    value={password}
                    className="form-control"
                    placeholder="Password"
                  />
              </Form.Group>
              <Form.Group className="mb-5">
                  <Form.Control
                    type="password"
                    onChange={e=>setConformPassword(e.target.value)}
                    value={conformPassword}
                    className="form-control"
                    placeholder="Conform Password"
                  />
               </Form.Group>
            <Button type="submit" className="common-btn mb-3" onClick={(e)=>submitForget(e)}>
              Submit Code
            </Button>
            <p>
              Go to signin page <Link to="/">Sign in</Link>
            </p>
          </Form>
          }
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = { toggleLoader, login, setUserHaveEmail, setUserForget };

const mapStateToProps = ({ Auth }) => {
  let { isLogin,getUserHaveEmail, getUserForget } = Auth;
  return { isLogin,getUserForget, getUserHaveEmail };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
