import React, { useState,Fragment,useEffect } from "react";
import ReactTable from "react-table-6";
import { connect } from "react-redux";
import { Link , Navigate} from "react-router-dom";
import { message, Space } from 'antd';
import html2pdf from 'html2pdf.js';
import moment from "moment";
import {Form, Button, Modal} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toggleLoader, login, getAllUserCase,getUserProfile, deleteCase,setCaseComplete, setNextDateFilter } from "../store/actions/Auth";
import WhatsApp from "./WhatsappShare";

import 'moment/locale/ur';
import "./index.css";
import "./UrduForm.css";
import 'react-table-6/react-table.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
const lawyerDiary = "/lawyerdirary.png";


const Signin = (props) => {

const textareaRef = React.useRef(null);
 let [cases, setCases] = useState([]);
 let [changeNextAction, setChangeNextAction] = useState();

 let [itemPerRow, setItemPerRow] = useState(30);
 let [next, setNext] = useState(itemPerRow);
 let [allCases, setAllCases] = useState([]);
 let [startDate, setStartDate] = useState();
 let [endDate, setEndDate] = useState();
 let [searchText, setSearchText] = useState("");
 let [selectedOption, setSelectedOption] = useState("all");

 const urduAlphabetMapping = {
  q: 'ق', w: 'و', e: 'ع', r: 'ر', t: 'ت', y: 'ے', u: 'ء', i: 'ی',
  p: 'پ', a: 'ا', s: 'س', d: 'د', f: 'ف', g: 'گ', h: 'ح', j: 'ج',
  k: 'ک', l: 'ل', z: 'ز', x: 'ش', c: 'چ', v: 'ط', b: 'ب', n: 'ن',
  m: 'م', Q: ' ْ', W: ' ٔ', E: ' ٰ', R: 'ڑ', T: 'ٹ', Y: ' َ', U: 'ئ',
  I: ' ِ', O: 'ۃ', P: ' ُ', A: 'آ', S: 'ص', D: 'ڈ', F: 'أ', G: 'غ', 
  H: 'ھ', J: 'ض', K: 'خ', L: 'ٖ', Z: 'ذ', X: 'ژ', C: 'ث', V: 'ظ', 
  B: ' ً', N: 'ں', M:' ّ', o: 'ہ'
};  
 const handleSearchChange = event => {
   setSearchText(event.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''));
 };

 const handleOptionChange = (event) => {
  setSelectedOption(event.target.value);
};

  useEffect(()=>{
    if(props.isLogin){
      props.toggleLoader({message:"Load cases...", status:true});
      props.getUserProfile();
      props.getAllUserCase();
    }
  },[props.isLogin,props.currentLanguage]);

  useEffect(()=>{
    if(props.setAllUserCase.length > 0) {
      // setItemPerRow(30)
      setAllCases(props.setAllUserCase)
      setChangeNextAction();
    }
    else{
      setAllCases([])
    }
  },[props.setAllUserCase]);

  useEffect(()=>{
    const textarea = textareaRef.current;
    if(textarea){
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  },[textareaRef.current]);
  
  const filterByDates = async () => {
    if(props.setAllUserCase.length == 0) return;
    if(!startDate) return message.error({ type: 'error', content: "Please Select From Date"});
    if(!endDate) return message.error({ type: 'error', content: "Please Select To Date"});
    let filterDates = props.setAllUserCase.filter(item=>{
      return new Date(item.nextDate) >= new Date(startDate) && new Date(item.nextDate) <= new Date(endDate)
    });
    setItemPerRow(30);
    setAllCases(filterDates);
  }

  function compareText(text1, text2) {
    const cleanText1 = text1.replace(/\s/g, '').trim();
    const cleanText2 = text2.replace(/\s/g, '').trim();
    return cleanText1.includes(cleanText2);
  }

  const filterByData = async () => {
    if(props.setAllUserCase.length == 0) return;
    if(!searchText) {
      setItemPerRow(30);
      setAllCases(props.setAllUserCase);
      return;
    };
    // if(!searchText) return message.error({ type: 'error', content: "Please Search Query"});
    if(selectedOption == "name"){
      const filteredData = props.setAllUserCase.filter(item => compareText(item.clientname, searchText));
      setItemPerRow(30);
      setAllCases(filteredData);
    }else if(selectedOption == "phone"){
      let filterDates = props.setAllUserCase.filter(item=>{
        if(item?.phoneno) return item.phoneno.toString() == searchText.toString()
      });
      setItemPerRow(30);
      setAllCases(filterDates);
    }else if(selectedOption == "title"){
      const filteredData = props.setAllUserCase.filter(item => compareText(item.title, searchText));
      setItemPerRow(30);
      setAllCases(filteredData);
    }else if(selectedOption == "caseNo"){
      let filterDates = props.setAllUserCase.filter(item=>{
        if(item?.caseNo) return item.caseNo.toString() == searchText.toString()
      });
      setItemPerRow(30);
      setAllCases(filterDates);
    }else if(selectedOption == "adaalat"){
      const filteredData = props.setAllUserCase.filter(item => compareText(item.addalat, searchText));
      setItemPerRow(30);
      setAllCases(filteredData);
    }else {
      setItemPerRow(30);
      setAllCases(props.setAllUserCase);
    }
  }

  if(!props.isLogin) return <Navigate to="/" />

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const input = document.getElementById('printableArea');
    const options = {
      filename: 'downloaded_page.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    };
    html2pdf().set(options).from(input).save();
  };  

  const submitDelete =({_id}) =>{
    props.toggleLoader({message:"Delete cases...", status:true});
    props.deleteCase({_id})
  }

const handleMoreImage = () => {
    setNext(next + itemPerRow);
};

const caseComplete = ({_id}) => {
  props.toggleLoader({message:"Complete case...", status:true});
  props.setCaseComplete({_id});
}


const handleTextareaInput = (index,action,_id) => {
  nextAction({index,nextAction:action});
  const textarea = textareaRef.current;
  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
};

const nextDate = ({index,nextDate}) =>{
  allCases[index]['nextDate'] = nextDate;
  setAllCases([...allCases])
  setChangeNextAction(index);
}

const nextAction = ({index,nextAction}) =>{
  allCases[index]['nextAction'] = nextAction;
  setAllCases([...allCases])
  setChangeNextAction(index);
}

const submitNextDate = ({_id,nextDate,nextAction}) => {
  let newDate = new Date();
  props.toggleLoader({message:"Update cases...", status:true});
  props.setNextDateFilter({ nextDate,nextAction, _id,date:newDate})
}


  return (
    <>
      <section className="wrapper">
        <div className="container-fluid font-tables urdu-form">
        <div className="content">
        <div className="main-container mintedNFT">
          <div className="edit-add">
          <table class="table table-striped table-bordered print-table" id="printableArea" >
                <thead className="font-tables urdu-form">
                  <tr>
                    <th colspan="9" className="th"  style={{backgroundColor:"white", border:0}}>
                    <div class="row" style={{marginTop:50}}>
                    <div class="col-2 offset-md-1">
                    <img src={lawyerDiary} width={100}/>
                    </div>
                    <div class="col-9">
                      <h3 className="text-center font-tables-headings" style={{padding:10}}> {props.profile?.name ? props.profile.name : ""}</h3>
                      {
                      props.currentLanguage == "urdu" ?  
                      <h4 className="text-center font-tables"><big> {props.profile?.phone ? props.profile.phone : "" } </big> <big>{props.profile?.address ? props.profile.address : ""}</big> </h4>
                      : 
                      <h4 className="text-center font-tables"> <big>{props.profile?.address ? props.profile.address : ""}</big> <big> {props.profile?.phone ? props.profile.phone : "" } </big></h4>
                      }
                    </div>
                  </div>
                    </th>
                  </tr>  
                  </thead>
           </table>
           {props.currentLanguage == "urdu" ?
            <div className="edit-add-title2 non-printable">
            <div className="row justify-content-center mt-5">
            <div className="col-md-8">
            {selectedOption == "date" ?
            <div className="input-group mb-3">
              <input
                type="date"
                className="form-control"
                placeholder="From Date..."
                value={startDate}
                onChange={e=>setStartDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control"
                placeholder="From Date..."
                value={endDate}
                onChange={e=>setEndDate(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit" onClick={filterByDates}>
                  Search
                </button>
                <Button style={{marginLeft:10}} onClick={handlePrint} >
                    Print
                </Button>
              </div>
            </div>
            :
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control urdu-form"
                placeholder="... تلاش کریں"
                value={searchText}
                onChange={handleSearchChange}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit" onClick={filterByData}>
                  Search
                </button>
                <Button style={{marginLeft:10}} onClick={handlePrint} >
                    Print
                  </Button>
              </div>
              {/* <div className="urdu-form">
                  <Button style={{margin:10}} onClick={handlePrint} >
                    Print
                  </Button>
                  <Button style={{margin:10}} onClick={handleDownload} >
                    Download
                  </Button>
            </div> */}
            </div>
            }
            <div className="input-group mb-3 urdu-form">
              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="name"
                  checked={selectedOption === "name"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">کلائنٹ کا نام</label>
              </div>

              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="phone"
                  checked={selectedOption === "phone"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">کلائنٹ فون</label>
              </div>

              {/* <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="title"
                  checked={selectedOption === "title"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">Case Title</label>
              </div> */}
              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="date"
                  checked={selectedOption === "date"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">From Date - To Date</label>
              </div>
              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="caseNo"
                  checked={selectedOption === "caseNo"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">کیس نمبر</label>
              </div>

              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="adaalat"
                  checked={selectedOption === "adaalat"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">عدالت</label>
              </div>

              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="all"
                  checked={selectedOption === "all"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">تمام</label>
              </div>
            </div>
            </div>
            </div>
            </div>
            :
            <div className="edit-add-title2 non-printable">
            <div className="row justify-content-center mt-5">
            <div className="col-md-8">
            {selectedOption == "date" ?
            <div className="input-group mb-3">
              <input
                type="date"
                className="form-control"
                placeholder="From Date..."
                value={startDate}
                onChange={e=>setStartDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control"
                placeholder="From Date..."
                value={endDate}
                onChange={e=>setEndDate(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit" onClick={filterByDates}>
                  Search
                </button>
                <Button style={{marginLeft:10}} onClick={handlePrint} >
                    Print
                </Button>
              </div>
            </div>
            :
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search ..."
                value={searchText}
                onChange={e=>setSearchText(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit" onClick={filterByData}>
                  Search
                </button>
                <Button style={{marginLeft:10}} onClick={handlePrint} >
                    Print
                  </Button>
              </div>
            </div>
            }
            <div className="input-group mb-3">
              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="name"
                  checked={selectedOption === "name"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">Client Name</label>
              </div>

              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="phone"
                  checked={selectedOption === "phone"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">Client Phone</label>
              </div>

              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="date"
                  checked={selectedOption === "date"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">From Date - To Date</label>
              </div>
              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="caseNo"
                  checked={selectedOption === "caseNo"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">Case No</label>
              </div>

              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="adaalat"
                  checked={selectedOption === "adaalat"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label"></label>
              </div>

              <div className="form-check" style={{paddingRight:20}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="filterOption"
                  value="all"
                  checked={selectedOption === "all"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label">All</label>
              </div>
            </div>
            </div>
            </div>
            </div>
            }
            {props.currentLanguage == "urdu" ?
            <Fragment>
            <table class="table table-striped table-bordered print-table" id="printableArea" >
                <thead className="font-tables urdu-form">
                  <tr>
                    <th class="urdu-font-14 text-center non-printable">ڈیلیٹ</th>
                    <th class="urdu-font-14 text-center non-printable">شیئر</th>
                    <th class="urdu-font-14 text-center non-printable"> مکمل کریں</th>
                    <th class="urdu-font-14 text-center">آئندہ کاروائی		</th>
                    <th class="urdu-font-14 text-center">آئندہ تاریخ پیشی</th>
                    <th class="urdu-font-14 text-center">امروز کاروائی</th>
                    <th class="urdu-font-14 text-center">عدالت</th>
                    <th class="urdu-font-14 text-center">عنوان	</th>
                    <th class="urdu-font-14 text-center">نوعیت/جرم</th>
                    <th class="urdu-font-14 text-center">مقدمہ/ سال / پولیس اسٹیشن</th>
                    <th class="urdu-font-14 text-center">سابقہ تاریخ پیشی</th>
                    <th class="urdu-font-14 text-center">کیس نمبر</th>
                    <th class="urdu-font-14 text-center">نمبرشمار</th>
                   </tr>
                </thead>
                <tbody className="urdu-form font-tables">
                {allCases && allCases.length > 0 && allCases.slice(0, next).map((item,index)=>
                      <tr key={index}>
                        <th class="urdu-font-14 text-center non-printable"><Button onClick={()=>submitDelete({_id:item['_id']})}>ڈیلیٹ</Button></th>
                         <td style={{wordBreak:"break-word"}} className="non-printable"><WhatsApp data={{
                        " عدالت": item?.['addalat'],
                        "آئندہ کاروائی":  item?.['nextAction'],
                        "آئندہ تاریخ پیشی	":  item?.['nextDate'],
                        "امروز کاروائی":  item?.['lastAction'],
                        }} phoneno={item['phoneno']} /></td>
                        <th class="urdu-font-14 text-center non-printable" style={{width:120}}><Button onClick={()=>caseComplete({_id:item['_id']})}> مکمل کریں</Button></th>
                        {/* <td>{item["nextAction"] ? item["nextAction"] : "-"}</td>
                        <td style={{wordBreak:"break-word", width:130}}> {item["nextDate"] ? moment(item["nextDate"]).format('DD.MM.YYYY') : "-"} </td> */}
                        <td style={{wordBreak:"break-word", width:20}}><textarea 
                        key={index}
                        className="expanding-textarea urdu-form non-printable"
                        ref={textareaRef}
                        style={{width:120, border:"0px"}} type="text" value={item["nextAction"]}  
                        onChange={e=>handleTextareaInput(index,e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''),item["_id"])} /></td>
                        <td style={{wordBreak:"break-word", width:20}}>
                          <span className="non-printable">
                            <DatePicker
                              className="custom-datepicker"
                              selected={item["nextDate"] ? new Date(item["nextDate"]) : ""}
                              dateFormat="dd.MM.yyyy" // Set the desired format
                              placeholderText="Select a date"
                              onChange={e=>nextDate({index,nextDate:e,_id:item["_id"]})}/> 
                              {changeNextAction == index  &&  <Button className="non-printable" onClick={()=>submitNextDate({nextDate:item["nextDate"],nextAction:item["nextAction"],_id:item["_id"]})}>Submit</Button>} 
                          </span>
                        </td>
                        <td style={{wordBreak:"break-word"}}>{item["lastAction"] ? item["lastAction"] : "-"}</td>
                        <td style={{wordBreak:"break-word"}}>{item["addalat"] ? item["addalat"] : "-"}</td>
                        <td style={{wordBreak:"break-word"}}>{ item["titleA"] ? item["titleA"] : "-" } بنام { item["titleB"] ? item["titleB"] : "-" }</td>
                        <td style={{wordBreak:"break-word"}}>{item["criminal"] ? item["criminal"] : "-"}</td>
                        <td style={{wordBreak:"break-word"}}>{item["caseYearPolicestation"] ? item["caseYearPolicestation"] : "-"} </td>
                        <td style={{wordBreak:"break-word", width:130}}>{item["currentDate"] ? moment(item["currentDate"]).format('DD.MM.YYYY'): "-"}</td>
                        <td style={{wordBreak:"break-word", width:130}}><Link to={"/viewcase/"+item['_id']}>{item["caseNo"] ? item["caseNo"] : "-"}</Link></td>
                        <td style={{wordBreak:"break-word"}}>{index + 1}</td>
                      </tr>
                    )}
               </tbody>
              </table>
            </Fragment>
            :
            <Fragment>
            <table class="table table-striped table-bordered print-table" id="printableArea" >
                <thead className="font-tables">
                  <tr>
                    <th class="urdu-font-14 text-center">No.</th>
                    <th class="urdu-font-14 text-center">Case No.</th>
                    <th class="urdu-font-14 text-center">Previous Date.</th>
                    <th class="urdu-font-14 text-center">Case/Police Station.</th>
                    <th class="urdu-font-14 text-center">Offence/Case.</th>
                    <th class="urdu-font-14 text-center">Case Title.</th>
                    <th class="urdu-font-14 text-center">Court Name.</th>
                    <th class="urdu-font-14 text-center">Today Proceeding.</th>
                    <th class="urdu-font-14 text-center">Next Proceeding.</th>
                    <th class="urdu-font-14 text-center">Next date.</th>
                    <th class="urdu-font-14 text-center non-printable"> Complete Case.</th>
                    <th class="urdu-font-14 text-center non-printable">Delete Case.</th>
                    <th class="urdu-font-14 text-center non-printable">Share.</th>
                   </tr>
                </thead>
                <tbody className="font-tables">
                {allCases && allCases.length > 0 && allCases.slice(0, next).map((item,index)=>
                      <tr key={index}>
                        <td style={{wordBreak:"break-word",textAlign:"center"}}>{index + 1}</td>
                        <td style={{wordBreak:"break-word",textAlign:"left"}}><Link to={"/viewcase/"+item['_id']}>{item["caseNo"] ? item["caseNo"] : "-"}</Link></td>
                        <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["currentDate"] ? moment(item["currentDate"]).format('DD.MM.YYYY'): "-"}</td>
                        <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["caseYearPolicestation"] ? item["caseYearPolicestation"] : "-"} </td>
                        <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["criminal"] ? item["criminal"] : "-"}</td>
                        <td style={{wordBreak:"break-word",textAlign:"left"}}>{ item["titleA"] ? item["titleA"] : "-" } VS { item["titleB"] ? item["titleB"] : "-" }</td>
                        <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["addalat"] ? item["addalat"] : "-"}</td>
                        <td style={{wordBreak:"break-word",textAlign:"left"}}>{item["lastAction"] ? item["lastAction"] : "-"}</td>
                        
                        <td style={{wordBreak:"break-word"}}><textarea 
                        key={index}
                        className="expanding-textarea non-printable"
                        ref={textareaRef}
                        style={{width:120, border:"0px"}} type="text" value={item["nextAction"]}  
                        onChange={e=>handleTextareaInput(index,e.target.value,item["_id"])} /></td>
                        <td style={{wordBreak:"break-word"}}>
                          <span className="non-printable">
                            <DatePicker
                              className="custom-datepicker"
                              selected={item["nextDate"] ? new Date(item["nextDate"]) : ""}
                              dateFormat="dd.MM.yyyy" // Set the desired format
                              placeholderText="Select a date"
                              onChange={e=>nextDate({index,nextDate:e,_id:item["_id"]})}/> 
                              {changeNextAction == index  &&  <Button className="non-printable" onClick={()=>submitNextDate({nextDate:item["nextDate"],nextAction:item["nextAction"],_id:item["_id"]})}>Submit</Button>} 
                          </span>
                        </td>
                        <th class="urdu-font-14 text-center non-printable" style={{width:120}}><Button onClick={()=>caseComplete({_id:item['_id']})}> Complete</Button></th>
                        <th class="urdu-font-14 text-center non-printable"><Button onClick={()=>submitDelete({_id:item['_id']})}>Delete</Button></th>
                         <td style={{wordBreak:"break-word"}} className="non-printable text-center"><WhatsApp data={{
                        "Court Name": item?.['addalat'],
                        "Next Proceeding.":  item?.['nextAction'],
                        "Next date.":  item?.['nextDate'],
                        "Today Proceeding.":  item?.['lastAction'],
                        }} phoneno={item['phoneno']} /></td>
                      </tr>
                    )}
               </tbody>
              </table>
            </Fragment>
            }
            {next < allCases?.length && (
            <div className="text-center non-printable">
               <Button onClick={handleMoreImage}>Load More</Button>
            </div>
            )}
          </div>
        </div>
        </div>
        </div>
      </section>

    </>
  );
};

const mapDispatchToProps = { toggleLoader, login, getAllUserCase,getUserProfile,deleteCase,setCaseComplete,setNextDateFilter };

const mapStateToProps = ({ Auth }) => {
  let { isLogin, setAllUserCase,profile,currentLanguage } = Auth;
  return { isLogin, setAllUserCase,profile,currentLanguage };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
