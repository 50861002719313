import { all, takeEvery } from 'redux-saga/effects';
import { login, setRegisterUser,setNewCase, getAllCases, getSingleCase, updateSingleCase, setVerifyCode,setUserEmail,
  setUserForget, getAllUserCase, getProfile, getUserProfile, setNextAction, setNextDate, getCaseHistory, deleteCase,
  getAllCompleteCases, setCaseComplete, setCaseNotComplete, setNextDateFilter,setChangeLanguage, deleteCaseHistory,
  setNotice, getApiNotice, removeNotice} from './Auth';

export default function* rootSaga() {
  yield all([
    yield takeEvery('LOGIN', login),
    yield takeEvery('SET_REGISTER_USER', setRegisterUser),
    yield takeEvery('SET_NEW_CASE', setNewCase),
    yield takeEvery('GET_ALL_CASE', getAllCases),
    yield takeEvery('GET_SINGLE_CASE', getSingleCase),
    yield takeEvery('UPDATE_SINGLE_CASE', updateSingleCase),
    yield takeEvery('SET_VERIFY_CODE', setVerifyCode),
    yield takeEvery('SET_USER_EMAIL', setUserEmail),
    yield takeEvery('SET_USER_FORGET', setUserForget),
    yield takeEvery('GET_ALL_USER_CASE', getAllUserCase),
    yield takeEvery('GET_PROFILE', getProfile),
    yield takeEvery('DELETE_CASE_HISTORY', deleteCaseHistory),
    yield takeEvery('GET_USER_PROFILE', getUserProfile),
    yield takeEvery('SET_NEXT_ACTION', setNextAction),
    yield takeEvery('SET_NEXT_DATE', setNextDate),
    yield takeEvery('GET_CASE_HISTORY', getCaseHistory),
    yield takeEvery('DELETE_CASE', deleteCase),
    yield takeEvery('GET_ALL_COMPLETE_USER_CASE', getAllCompleteCases),
    yield takeEvery('SET_USER_CASE_COMPLETE', getAllCompleteCases),
    yield takeEvery('SET_USER_CASE_COMPLETE', setCaseComplete),
    yield takeEvery('SET_USER_CASE_NOT_COMPLETE', setCaseNotComplete),
    yield takeEvery('SET_NEXT_DATE_FILTER', setNextDateFilter),
    yield takeEvery('SET_CHNAGE_LANGUAGE', setChangeLanguage),

    yield takeEvery('SET_NOTICE', setNotice),
    yield takeEvery('GET_API_NOTICE', getApiNotice),
    yield takeEvery('REMOVE_NOTICE', removeNotice),
  ]);
}