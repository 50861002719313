// import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { Button, Drawer, theme ,Divider,Switch, Space} from 'antd';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { connect } from "react-redux";
import { Link , Navigate, useNavigate} from "react-router-dom";
import { PicLeftOutlined, OrderedListOutlined,PlusCircleOutlined ,LogoutOutlined} from '@ant-design/icons';
import { setLogout, setChangeLanguage } from "../store/actions/Auth";
function OffcanvasExample(props) {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const caseList = () =>{
    navigate("/mainpage");
    onClose();
  }

  const newCase = () =>{
    navigate("/newcase");
    onClose();
  }

  const findCase = () =>{
    navigate("/filterpage");
    onClose();
  }

  const updateProfile = () => {
    navigate("/updateProfile");
    onClose();
  }

  const completeCases = () => {
    navigate("/completeCases");
    onClose();
  }
  const noticeCases = () => {
    navigate("/notice");
    onClose();
  }
  
  const logOut = () =>{
    props.setLogout();
    onClose();
  }

  return (
    <>
      <Drawer
        title={<h3>Lawyers Diary</h3>}
        placement="right"
        onClose={onClose}
        closable={true}
        className='non-printable'
        // width={500}
        open={open}
        getContainer={false}
      >
         <Button type="link" style={{ color: '#198754', display: 'inline-flex', alignItems: 'center' }} onClick={caseList}>
           <OrderedListOutlined style={{ color: '#198754', marginRight: '4px', fontSize: '18px' }} />
           <span>Today Case</span>
         </Button>
         <Divider />
         <Button type="link" style={{ color: '#198754', display: 'inline-flex', alignItems: 'center' }}  onClick={newCase}>
           <PlusCircleOutlined style={{ color: '#198754', marginRight: '4px', fontSize: '18px' }} />
           <span>New Case</span>
         </Button>
         <Divider />
         <Button type="link" style={{ color: '#198754', display: 'inline-flex', alignItems: 'center' }}  onClick={findCase}>
           <OrderedListOutlined style={{ color: '#198754', marginRight: '4px', fontSize: '18px' }} />
           <span>All Cases</span>
         </Button>
         <Divider />
         <Button type="link" style={{ color: '#198754', display: 'inline-flex', alignItems: 'center' }}  onClick={completeCases}>
           <OrderedListOutlined style={{ color: '#198754', marginRight: '4px', fontSize: '18px' }} />
           <span>Complete Cases</span>
         </Button>
         <Divider />
         <Button type="link" style={{ color: '#198754', display: 'inline-flex', alignItems: 'center' }}  onClick={noticeCases}>
           <OrderedListOutlined style={{ color: '#198754', marginRight: '4px', fontSize: '18px' }} />
           <span>Notice Cases</span>
         </Button>
         <Divider />
         <Button type="link" style={{ color: '#198754', display: 'inline-flex', alignItems: 'center' }}  onClick={updateProfile}>
           <OrderedListOutlined style={{ color: '#198754', marginRight: '4px', fontSize: '18px' }} />
           <span>Profile</span>
         </Button>
         <Divider />
         
         <Button type="link" style={{ color: '#198754', display: 'inline-flex', alignItems: 'center' }}  onClick={logOut}>
           <LogoutOutlined style={{ color: '#198754', marginRight: '4px', fontSize: '18px' }} />
           <span>Logout</span>
         </Button>

         <Divider />

        <Space direction="vertical" style={{ color: '#198754', display: 'inline-flex', alignItems: 'center' }} >
           <span>Select Language: <Switch checkedChildren="اردو" unCheckedChildren="English" defaultChecked={props.currentLanguage == "urdu" ? true : false}  onChange={e=>props.setChangeLanguage(e== true ? {languageType:"urdu"} :{languageType:"english"})}/> </span> 
        </Space>

      </Drawer>
    {/* </div> */}
    { props.isLogin &&
      [false].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3 non-printable">
          <Container>
            <Button style={{borderColor: '#198754'}} onClick={showDrawer}>
                <PicLeftOutlined style={{color:'#198754',borderColor: '#198754'}}/>
            </Button>

            <h5 style={{color: '#198754'}} className='end' >
                  Lawyers Diary
            </h5>

          </Container>
        </Navbar>
      ))}
    
    </>
  );
}

const mapDispatchToProps = { setLogout,setChangeLanguage };

const mapStateToProps = ({ Auth }) => {
  let { isLogin,currentLanguage } = Auth;
  return { isLogin,currentLanguage };
};

export default connect(mapStateToProps, mapDispatchToProps)(OffcanvasExample);
