import React, { useState } from "react";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link , Navigate, useParams} from "react-router-dom";
import { Container, Form, Button } from 'react-bootstrap';
import {  message, Space } from 'antd';
import moment from "moment";
import DatePicker from "react-datepicker";
import { toggleLoader, login , getSingleCase, updateCase} from "../store/actions/Auth";
// import "./index.css";
import "./UrduForm.css";
import "react-datepicker/dist/react-datepicker.css";

const Signin = (props) => {

  let [caseNo,setCaseNo] = useState(props.setSingleCase.caseNo ? props.setSingleCase.caseNo : "");
  let [currentDate,setCurrentDate] = useState("");
  let [caseYearPolicestation,setCaseYearPolicestation] = useState(props.setSingleCase.caseYearPolicestation ? props.setSingleCase.caseYearPolicestation : "");
  let [criminal,setCriminal] = useState(props.setSingleCase.criminal ? props.setSingleCase.criminal : "");
  let [caseURL,setCaseURL] = useState(props.setSingleCase.caseURL ? props.setSingleCase.caseURL : "");
  let [titleA,setTitleA] = useState(props.setSingleCase.titleA ? props.setSingleCase.titleA : "");
  let [titleB,setTitleB] = useState(props.setSingleCase.titleB ? props.setSingleCase.titleB : "");
  let [addalat,setAddalat] = useState(props.setSingleCase.addalat ? props.setSingleCase.addalat : "");
  let [lastAction,setLastAction] = useState(props.setSingleCase.lastAction ? props.setSingleCase.lastAction : "");
  let [nextDate,setnextDate] = useState("");
  let [nextAction,setnextAction] = useState(props.setSingleCase.nextAction ? props.setSingleCase.nextAction : "");
  let [clientname,setclientname] = useState(props.setSingleCase.clientname ? props.setSingleCase.clientname : "");
  let [phoneno,setphoneno] = useState(props.setSingleCase.phoneno ? props.setSingleCase.phoneno : "");
  let [totalFee,settotalFee] = useState(props.setSingleCase.totalFee ? props.setSingleCase.totalFee : "");
  let [totalPay,settotalPay] = useState(props.setSingleCase.totalPay ? props.setSingleCase.totalPay : "");
  let [outstandingFee,setoutstandingFee] = useState(props.setSingleCase.outstandingFee ? props.setSingleCase.outstandingFee : "");

  const { id } = useParams();

  React.useEffect(()=>{
    props.getSingleCase({id})
  },[id])

  React.useEffect(()=>{
    if(props.setSingleCase && Object.keys(props.setSingleCase).length > 0){
      let {caseNo,caseURL,currentDate,caseYearPolicestation,criminal,titleA,titleB,addalat,lastAction,nextDate,nextAction,clientname,phoneno,totalFee,totalPay,outstandingFee} = props.setSingleCase;
      setCaseNo(caseNo ? caseNo : "");
      setCurrentDate(currentDate ? new Date(currentDate) : "");
      setCaseYearPolicestation(caseYearPolicestation ? caseYearPolicestation : "");
      setCriminal(criminal ? criminal : "");
      setCaseURL(caseURL ? caseURL : "");
      setTitleA(titleA ? titleA : "");
      setTitleB(titleB ? titleB : "");
      setAddalat(addalat ? addalat : "");
      setLastAction(lastAction ? lastAction : "");
      setnextDate(nextDate ? new Date(nextDate) : "");
      setnextAction(nextAction ? nextAction : "");
      setclientname(clientname ? clientname : "");
      setphoneno(phoneno ? phoneno : "");
      settotalFee(totalFee ? totalFee : "");
      settotalPay(totalPay ? totalPay : "");
      setoutstandingFee(outstandingFee ? outstandingFee : "");
    }
  },[props.setSingleCase])

  const submitForm = (e) => {
    e.preventDefault();
    if(!caseNo) return message.error({ type: 'error', content: "Please Add Case No"});
    props.toggleLoader({message:"case update...", status: true});
    props.updateCase({id,caseNo,caseURL,currentDate,caseYearPolicestation,criminal,titleA,titleB,addalat,lastAction,nextDate,nextAction,clientname,phoneno,totalFee,totalPay,outstandingFee})
  }

  const totalPayAmount = (e) =>{
      totalPay = parseInt(totalFee) - parseInt(e.target.value)
      settotalPay(e.target.value);
      setoutstandingFee(totalPay)
  }

  const totalFeeAmount = (e) => {
    totalPay = parseInt(e.target.value) - parseInt(totalPay)
    settotalFee(e.target.value);
    settotalPay(totalPay);
    setoutstandingFee(totalPay)
  }
  
  const urduAlphabetMapping = {
    q: 'ق', w: 'و', e: 'ع', r: 'ر', t: 'ت', y: 'ے', u: 'ء', i: 'ی',
    p: 'پ', a: 'ا', s: 'س', d: 'د', f: 'ف', g: 'گ', h: 'ح', j: 'ج',
    k: 'ک', l: 'ل', z: 'ز', x: 'ش', c: 'چ', v: 'ط', b: 'ب', n: 'ن',
    m: 'م', Q: ' ْ', W: ' ٔ', E: ' ٰ', R: 'ڑ', T: 'ٹ', Y: ' َ', U: 'ئ',
    I: ' ِ', O: 'ۃ', P: ' ُ', A: 'آ', S: 'ص', D: 'ڈ', F: 'أ', G: 'غ', 
    H: 'ھ', J: 'ض', K: 'خ', L: 'ٖ', Z: 'ذ', X: 'ژ', C: 'ث', V: 'ظ', 
    B: ' ً', N: 'ں', M:' ّ', o: 'ہ'
  };  

  if(!props.isLogin) return <Navigate to="/" />

  return (
    <>
      <section className="wrapper font-tables" id="printableArea">
        <div className="container urdu-form">
         <h2 className="text-center"> Update Case</h2>
          {props.currentLanguage == "urdu" ?
         <Form onSubmit={submitForm}>
            <Form.Group className="formName">
              <Form.Label className="urdu-label">کیس نمبر</Form.Label>
              <Form.Control  className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" کیس نمبر" onChange={e=>setCaseNo(e.target.value)} value={caseNo}/>
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label">سابقہ تاریخ پیشی</Form.Label>
              {/* <Form.Control type="date" className="form-control urdu-input" lang="ur" dir="rtl" placeholder="سابقہ تاریخ پیشی" onChange={e=>setCurrentDate(e.target.value)} value={moment(currentDate).format("YYYY-MM-DD")}/> */}
             
              <DatePicker 
                className="form-control urdu-input" 
                dateFormat="dd/MM/yyyy" 
                lang="ur" dir="rtl" placeholder="سابقہ تاریخ پیشی" 
                selected={currentDate} 
                onChange={(date) => setCurrentDate(date)} 
              />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label">مقدمہ/ سال / پولیس اسٹیشن</Form.Label>
              <Form.Control as="textarea" rows={5}  className="form-control urdu-input" lang="ur" dir="rtl" placeholder="مقدمہ/ سال / پولیس اسٹیشن" onChange={e=>setCaseYearPolicestation(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={caseYearPolicestation}/>
            </Form.Group>


            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >ویب لنک</Form.Label>
              <Form.Control className="form-control urdu-input" placeholder="https://dsjlahore.punjab.gov.pk/casedetail/2c0" onChange={e=>setCaseURL(e.target.value)} value={caseURL}/>
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >نوعیت/جرم </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="نوعیت/جرم" onChange={e=>setCriminal(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={criminal}/>
            </Form.Group>


            <div class="row" style={{marginTop:50}}>
                <div class="col-5">
                  <Form.Group className="formEmail">
                    {/* <Form.Label className="urdu-label" > عنوان  </Form.Label> */}
                    <Form.Control style={{marginTop:40}} className="form-control urdu-input" lang="ur" dir="rtl" placeholder="B" onChange={e=>setTitleB(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={titleB} />
                  </Form.Group>
                </div>
                <div class="col-2">
                   <Form.Group style={{marginTop:55}}>
                    <h4> بنام </h4>
                  </Form.Group>
                </div>
                <div class="col-5">
                  <Form.Group className="formEmail">
                    <Form.Label className="urdu-label" > عنوان  </Form.Label>
                    <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="A" onChange={e=>setTitleA(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={titleA} />
                  </Form.Group>
                </div>
            </div>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > عدالت  </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="عدالت" onChange={e=>setAddalat(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={addalat} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > امروز کاروائی  </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="امروز کاروائی " onChange={e=>setLastAction(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={lastAction} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >  آئندہ تاریخ پیشی </Form.Label>
              {/* <Form.Control type="date" className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" آئندہ تاریخ پیشی" onChange={e=>setnextDate(e.target.value)} value={moment(nextDate).format("YYYY-MM-DD")} /> */}
              <DatePicker 
                className="form-control urdu-input" 
                dateFormat="dd/MM/yyyy" 
                lang="ur" dir="rtl" placeholder=" آئندہ تاریخ پیشی"
                selected={nextDate} 
                onChange={(date) => setnextDate(date)} 
              />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >  آئندہ کاروائی  </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder="  آئندہ کاروائی" onChange={e=>setnextAction(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={nextAction} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > کلائنٹ کا نام  </Form.Label>
              <Form.Control className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" کلائنٹ کا نام" onChange={e=>setclientname(e.target.value.split('').map(char => urduAlphabetMapping[char] || char).join(''))} value={clientname} />
            </Form.Group>

            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > فون نمبر  </Form.Label>
              <Form.Control type="tel" className="form-control urdu-input" lang="ur" dir="rtl" placeholder=" فون نمبر" onChange={e=>setphoneno(e.target.value)} value={phoneno} />
            </Form.Group>


            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" > کل فیس  </Form.Label>
              <Form.Control type="number" className="form-control urdu-input" lang="ur" dir="rtl" placeholder="کل فیس" onChange={e=>totalFeeAmount(e)} value={totalFee}  />
            </Form.Group>


            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >  فیس جو ادا کی گی   </Form.Label>
              <Form.Control type="number" className="form-control urdu-input" lang="ur" dir="rtl" placeholder="  فیس جو ادا کی گی " onChange={e=>totalPayAmount(e)} value={totalPay} />
            </Form.Group>


            <Form.Group className="formEmail">
              <Form.Label className="urdu-label" >  بقیہ فیس   </Form.Label>
              <Form.Control type="number" className="form-control urdu-input" lang="ur" dir="rtl" placeholder="  بقیہ فیس " onChange={e=>setoutstandingFee(e.target.value)} value={outstandingFee} disabled={true}/>
            </Form.Group>
            <div className="text-center">
                <Button  className="common-btn" style={{marginTop:30}} type="submit">
                  Submit
                </Button>
            </div>
           
          </Form>
          :
          <Form onSubmit={submitForm}>
          <Form.Group className="formName"  style={{textAlign:"left"}}>
            <Form.Label className="">Case No.</Form.Label>
            <Form.Control  className="form-control" placeholder=" Case No." onChange={e=>setCaseNo(e.target.value)} value={caseNo}/>
          </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label>Current Date.</Form.Label>
            {/* <Form.Control type="date" className="form-control" placeholder="Current Date." onChange={e=>setCurrentDate(e.target.value)} value={moment(currentDate).format("YYYY-MM-DD")}/> */}
            <DatePicker 
                className="form-control" 
                dateFormat="dd/MM/yyyy" 
                placeholder="Current Date."
                selected={currentDate} 
                onChange={(date) => setCurrentDate(date)} 
              />
          </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label>Case/Police Station.</Form.Label>
            <Form.Control as="textarea" rows={5}  className="form-control" placeholder="Case/Police Station." onChange={e=>setCaseYearPolicestation(e.target.value)} value={caseYearPolicestation}/>
          </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label>Web Link.</Form.Label>
              <Form.Control className="form-control" placeholder="https://dsjlahore.punjab.gov.pk/casedetail/2c0" onChange={e=>setCaseURL(e.target.value)} value={caseURL}/>
            </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label >Offence/Case. </Form.Label>
            <Form.Control className="form-control" placeholder="Offence/Case." onChange={e=>setCriminal(e.target.value)} value={criminal}/>
          </Form.Group>


          <div class="row" style={{marginTop:50}}>
              <div class="col-5">
                <Form.Group className="formEmail"  style={{textAlign:"left"}}>
                  <Form.Label > Title. </Form.Label>
                  <Form.Control className="form-control" placeholder="A" onChange={e=>setTitleA(e.target.value)} value={titleA} />
                </Form.Group>
              </div>
              <div class="col-2">
                 <Form.Group style={{marginTop:55}}>
                  <h4> VS </h4>
                </Form.Group> 
              </div>
              <div class="col-5">
                <Form.Group className="formEmail"  style={{textAlign:"left"}}>
                  {/* <Form.Label > عنوان  </Form.Label> */}
                  <Form.Control  style={{marginTop:40}} className="form-control" placeholder="B" onChange={e=>setTitleB(e.target.value)} value={titleB} />
                </Form.Group>
              </div>
          </div>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label > Court Name.  </Form.Label>
            <Form.Control className="form-control" placeholder="عدالت" onChange={e=>setAddalat(e.target.value)} value={addalat} />
          </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label > Today Proceeding. </Form.Label>
            <Form.Control className="form-control" placeholder="Today Proceeding." onChange={e=>setLastAction(e.target.value)} value={lastAction} />
          </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label > Next date. </Form.Label>
            {/* <Form.Control type="date" className="form-control" placeholder=" Next date." onChange={e=>setnextDate(e.target.value)} value={moment(nextDate).format("YYYY-MM-DD")} /> */}
            <DatePicker 
                className="form-control" 
                dateFormat="dd/MM/yyyy" 
                placeholder="Next date."
                selected={nextDate} 
                onChange={(date) => setnextDate(date)} 
              />
          </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label >  Next Proceeding. </Form.Label>
            <Form.Control className="form-control" placeholder="  Next Proceeding." onChange={e=>setnextAction(e.target.value)} value={nextAction} />
          </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label > Client Name. </Form.Label>
            <Form.Control className="form-control" placeholder=" Client Name." onChange={e=>setclientname(e.target.value)} value={clientname} />
          </Form.Group>

          <Form.Group className="formEmail"  style={{textAlign:"left"}}>
            <Form.Label > Phone No. </Form.Label>
            <Form.Control type="tel" className="form-control" placeholder=" Phone No." onChange={e=>setphoneno(e.target.value)} value={phoneno} />
          </Form.Group>


          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label > Total Fee.  </Form.Label>
            <Form.Control type="number" className="form-control" placeholder="Total Fee." onChange={e=>totalFeeAmount(e)} value={totalFee}  />
          </Form.Group>


          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label >  Fees to be paid.    </Form.Label>
            <Form.Control type="number" className="form-control" placeholder="  Fees to be paid.  " onChange={e=>totalPayAmount(e)} value={totalPay} />
          </Form.Group>


          <Form.Group className="formEmail" style={{textAlign:"left"}}>
            <Form.Label > Remaining Fees.  </Form.Label>
            <Form.Control type="number" className="form-control" placeholder=" Remaining Fees." onChange={e=>setoutstandingFee(e.target.value)} value={outstandingFee} disabled={true}/>
          </Form.Group>
          <div className="text-center">
              <Button  className="common-btn" style={{marginTop:30}} type="submit">
                Submit
              </Button>
          </div>
         
        </Form>
        }

        </div>
       
      </section>
    </>
  );
};

const mapDispatchToProps = { toggleLoader, login , getSingleCase, updateCase};

const mapStateToProps = ({ Auth }) => {
  let {isLogin,setSingleCase,currentLanguage } = Auth;
  return { isLogin,setSingleCase,currentLanguage };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
