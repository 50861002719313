export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const setLogin = (data) => ({
  type: 'SET_LOGIN',
  payload: data,
});

export const setLogout = () => ({
  type: 'LOGOUT'
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const setStyleButtons = (data) => ({
  type: 'SET_STYLE_BUTTON',
  payload: data,
});


export const setRegisterUser = (data) => ({
  type: 'SET_REGISTER_USER',
  payload: data,
});

export const getRegisterUser = (data) => ({
  type: 'GET_REGISTER_USER',
  payload: data,
});

export const setVerifyCode = (data) => ({
  type: 'SET_VERIFY_CODE',
  payload: data,
});

export const getVerifyCode = (data) => ({
  type: 'GET_VERIFY_CODE',
  payload: data,
});

export const setUserHaveEmail = (data) => ({
  type: 'SET_USER_EMAIL',
  payload: data,
});

export const getUserHaveEmail = (data) => ({
  type: 'GET_USER_EMAIL',
  payload: data,
});

export const setUserForget = (data) => ({
  type: 'SET_USER_FORGET',
  payload: data,
});

export const getUserForget = (data) => ({
  type: 'GET_USER_FORGET',
  payload: data,
});


export const setNewCase = (data) => ({
  type: 'SET_NEW_CASE',
  payload: data,
});

export const getNewCase = (data) => ({
  type: 'GET_NEW_CASE',
  payload: data,
});

export const getAllCase = (data) => ({
  type: 'GET_ALL_CASE',
  payload: data
});

export const getSingleCase = (data) => ({
  type: 'GET_SINGLE_CASE',
  payload: data,
});

export const setSingleCase = (data) => ({
  type: 'SET_SINGLE_CASE',
  payload: data,
});

export const updateCase = (data) =>({
  type: 'UPDATE_SINGLE_CASE',
  payload: data,
})

export const getAllUserCase = (data) => ({
  type: 'GET_ALL_USER_CASE',
  payload: data
});

export const setAllUserCase = (data) => ({
  type: 'SET_ALL_USER_CASE',
  payload: data
});

export const getAllCompleteUserCase = (data) => ({
  type: 'GET_ALL_COMPLETE_USER_CASE',
  payload: data
});

export const setAllCompleteUserCase = (data) => ({
  type: 'SET_ALL_COMPLETE_USER_CASE',
  payload: data
});

export const setCaseComplete = (data) => ({
  type: 'SET_USER_CASE_COMPLETE',
  payload: data
});

export const setNextDateFilter = (data) => ({
  type: 'SET_NEXT_DATE_FILTER',
  payload: data
});

export const setCaseNotComplete = (data) => ({
  type: 'SET_USER_CASE_NOT_COMPLETE',
  payload: data
});

export const setProfile = (data) => ({
  type: 'SET_PROFILE',
  payload: data
});

export const getProfile = (data) => ({
  type: 'GET_PROFILE',
  payload: data
});

export const getUserProfile = () => ({
  type: 'GET_USER_PROFILE'
});

export const setNextAction = (data) => ({
  type: 'SET_NEXT_ACTION',
  payload: data
});

export const setNextDate = (data) => ({
  type: 'SET_NEXT_DATE',
  payload: data
});

export const setCaseHistory = (data) => ({
  type: 'SET_CASE_HISTORY',
  payload: data
});

export const getCaseHistory = (data) => ({
  type: 'GET_CASE_HISTORY',
  payload: data
});

export const deleteCaseHistory = (data) => ({
  type: 'DELETE_CASE_HISTORY',
  payload: data
});

export const deleteCase = (data) => ({
  type: 'DELETE_CASE',
  payload: data
});


export const setChangeLanguage = (data) => ({
  type: 'SET_CHNAGE_LANGUAGE',
  payload: data
});

export const getChangeLanguage = (data) => ({
  type: 'GET_CHNAGE_LANGUAGE',
  payload: data
});

export const setNotice = (data) => ({
  type: 'SET_NOTICE',
  payload: data
});

export const getAPINotice = (data) => ({
  type: 'GET_API_NOTICE',
  payload: data
});

export const getNotice = (data) => ({
  type: 'GET_NOTICE',
  payload: data
});

export const removeNotice = (data) => ({
  type: 'REMOVE_NOTICE',
  payload: data
});