import React, { useEffect } from "react";
import EventBus from "eventing-bus";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import history from './history';
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { setLogout, login } from './store/actions/Auth';
import Header from "./components/Header";
import Loader from './components/loader';
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Mainpage from "./pages/mainpage";
import NewCase from "./pages/newcase";
import EditCase from "./pages/editcase";
import ViewCase from "./pages/viewcase";
import Forget from "./pages/Forget";
import UpdateProfile from "./pages/UpdateProfile";
import CompleteCases from "./pages/CompleteCases";
import NoticeCases from "./pages/NoticeCases";
import Filterpage from "./pages/filterpage";
import "react-toastify/dist/ReactToastify.css";

function App(props) {

  useEffect(()=>{
    EventBus.on('tokenExpired', () => props.setLogout());
    // EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    // EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    // EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  },[])

  return (
    <>
      <BrowserRouter>
      <Header />
      <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />
        <Routes history={history}>
          <Route path="/" element={<Signin />} />
          <Route path="signup" element={<Signup />} />
          <Route path="mainpage" element={<Mainpage />} />
          <Route path="filterpage" element={<Filterpage />} />
          <Route path="newcase" element={<NewCase />} />
          <Route path="editcase/:id" element={<EditCase />} />
          <Route path="viewcase/:id" element={<ViewCase />} />
          <Route path="forgotPassword" element={<Forget />} />
          <Route path="updateProfile" element={<UpdateProfile />} />
          <Route path="completeCases" element={<CompleteCases />} />
          <Route path="notice" element={<NoticeCases />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

const mapDispatchToProps = {
  setLogout, login
};

const mapStateToProps = ({ Auth }) => {
  let {  } = Auth;
  return {  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);