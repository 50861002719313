import React, { useState } from "react";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link , Navigate} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import { message, Space } from 'antd';
import { toggleLoader, login, getRegisterUser, getVerifyCode,getUserHaveEmail,getUserForget } from "../store/actions/Auth";
const lawyerDiary = "/lawyerdirary.png";

const Signin = (props) => {

  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    if(!password) return message.error({ type: 'error', content: 'Please enter password'});
    if(!email) return message.error({ type: 'error', content: 'Please enter email'});
    props.toggleLoader({status:true,message:"user login..."});
    props.login({email,password})
  }

  React.useEffect(()=>{
    props.getRegisterUser({});
    props.getUserHaveEmail(false)
    props.getVerifyCode(false)
    props.getUserForget(false)
  },[]);

  // let token = localStorage.getItem('token');
  if(props.isLogin) return <Navigate to="/mainpage" />

  return (
    <>
      <section className="wrapper">
       
        <div className="container align-items-center justify-content-center vh-100">
        {/* <div className="container d-flex align-items-center justify-content-center vh-100"> */}
           <div className="row vertical-center">
              {/* <div class="col col col-lg-3 offset-md-2" >
                  <img src={lawyerDiary} width={250}/>
              </div> */}
              <div  class="col">
              <Form className="sign-in" onSubmit={(e)=>submitForm(e)}>
              <h1>Sign in</h1>

              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  value={email}
                  onChange={e=>setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Control
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={e=>setPassword(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" className="common-btn mb-3" onClick={(e)=>submitForm(e)}>
                Sign in
              </Button>
              <p>
                Don’t have an account? <Link to="/signup">Sign up</Link>
              </p>
              <p>
                <Link to="/forgotPassword">Forgot Password</Link>
              </p>
            </Form>
              </div>
           </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = { toggleLoader, login, getRegisterUser, getVerifyCode,getUserHaveEmail,getUserForget };

const mapStateToProps = ({ Auth }) => {
  let { isLogin } = Auth;
  return { isLogin };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
