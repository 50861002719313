import { PURGE } from "redux-persist";

let initialState = {
  isLogin: localStorage.getItem("token") ? true : false,
  userStatus: false,
  styleButton: false,
  setLoader: { message: 'Please Wait...', status: false },
  getRegisterUser:{},
  getNewCase:{
    previousCases:[],
    currentCases:[],
  },
  setSingleCase: {},
  getVerifyCode: false,
  getUserHaveEmail: false,
  getUserForget: false,
  setAllUserCase: [],
  setAllCompleteUserCase: [],
  profile: {},
  caseHistory: [],
  getNotice:[],
  currentLanguage: localStorage.getItem("language") == "english" ? "english" : "urdu" 
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'GET_NOTICE':
      return {
        ...state,
        getNotice: payload,
      };

    case 'SET_LOGIN':
      return {
        ...state,
        isLogin: payload,
      };

    case 'LOGOUT':
      localStorage.removeItem('token');
      // window.location.href = '/'
      return {
        ...state,
        isLogin: false,
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'SET_STYLE_BUTTON':
      return {
        ...state,
        styleButton: payload
      }

  
    case 'GET_REGISTER_USER':
      return {
        ...state,
        getRegisterUser: payload
      }
    
    case 'GET_VERIFY_CODE':
      return {
        ...state,
        getVerifyCode: payload
      }

    case 'GET_NEW_CASE':
      return {
        ...state,
        getNewCase: payload
      }
    
    case 'SET_SINGLE_CASE':
      return {
        ...state,
        setSingleCase: payload
      }

    case 'GET_USER_EMAIL':
      return {
        ...state,
        getUserHaveEmail: payload
      }
    
    case 'GET_USER_FORGET':
      return {
        ...state,
        getUserForget: payload
      }
    
    case 'SET_ALL_USER_CASE':
      return {
        ...state,
        setAllUserCase: payload
      }

    case 'SET_PROFILE':
      return {
        ...state,
        profile: payload
      }
    
    case 'SET_CASE_HISTORY':
      return {
        ...state,
        caseHistory:  payload
      }

    case 'SET_ALL_COMPLETE_USER_CASE':
      return {
        ...state,
        setAllCompleteUserCase: payload
      }

    case 'GET_CHNAGE_LANGUAGE':
      localStorage.setItem("language",payload)
      return {
        ...state,
        currentLanguage: payload
      }

    default:
      return state;
  }
};

export default Auth;