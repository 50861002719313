import React from "react";
import { connect } from "react-redux";
import { Link , useNavigate, Navigate} from "react-router-dom";
import { useState } from "react";
import {Form, Button, Modal} from 'react-bootstrap';
import validator from 'validator';
import { message, Space } from 'antd';
import { toggleLoader, setRegisterUser, setVerifyCode } from "../store/actions/Auth";
const lawyerDiary = "/lawyerdirary.png";

const Signup = (props) => {

  // const [messageApi] = message.useMessage();
  
  const navigate = useNavigate();

  const [user,setUser] = useState("");
  const [password,setPassword] = useState("");
  const [conformPassword,setConformPassword] = useState("");
  const [verifyingCode, setVerifyingCode] = useState("");

  const handelSignup = (e) => {
    e.preventDefault();
    let isValis = validator.isEmail(user);
    if(!isValis) return message.error({ type: 'error', content: 'Email is invalid'});
    if(password !== conformPassword) return message.error({ type: 'error', content: 'Password not match'});
    props.toggleLoader({status:true,message:"user register..."});
    props.setRegisterUser({email:user,password});
  }

  const handelVerify = (e) => {
    e.preventDefault();
    let isValis = validator.isEmail(user);
    if(!isValis) return message.error({ type: 'error', content: 'Email is invalid'});
    if(!verifyingCode) return message.error({ type: 'error', content: 'Please enter code'});
    props.toggleLoader({status:true,message:"Code verifying..."});
    props.setVerifyCode({email:user,verificationCode:verifyingCode});
  }

  React.useEffect(()=>{
    if(props.getVerifyCode == true ){
      navigate("/");
    }
  },[props.getVerifyCode]);
  
  if(props.isLogin) return <Navigate to="/mainpage" />

  const renderStep = () => {

        return (
          <div className="wrapper">
            <div className="container d-flex align-items-center justify-content-center vh-100">            
              {Object.keys(props.getRegisterUser).length == 0 ?
              <Form noValidate className="sign-up" onSubmit={handelSignup}>
                {/* <img src={lawyerDiary} width={200}/> */}
                <h1>Sign up</h1>

                <Form.Group className="mb-3" controlId="email">
                  <Form.Control
                    type="email"
                    onChange={e=>setUser(e.target.value)}
                    value={user}
                    className="form-control"
                    placeholder="Email address"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    onChange={e=>setPassword(e.target.value)}
                    value={password}
                    className="form-control"
                    placeholder="Password"
                  />
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Control
                    type="password"
                    onChange={e=>setConformPassword(e.target.value)}
                    value={conformPassword}
                    className="form-control"
                    placeholder="Conform Password"
                  />
                </Form.Group>
                <Button type="submit" className="common-btn mb-3">
                  Register
                </Button>
                <p>
                  Already have an account? <Link to="/">Sign in</Link>
                </p>
              </Form>
              :
              <Form noValidate className="sign-up" onSubmit={handelVerify}>
              {/* <img src={lawyerDiary} width={200}/> */}
              <h1>Submit Code</h1>

              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="text"
                  onChange={e=>setVerifyingCode(e.target.value)}
                  value={verifyingCode}
                  className="form-control"
                  placeholder="Enter Code"
                />
              </Form.Group>
              <Button type="submit" className="common-btn mb-3">
                Submit Code
              </Button>
              <p>
                Already have an account? <Link to="/">Sign in</Link>
              </p>
            </Form>
              }
            </div>
          </div>
        );
     
  };

  return <div>{renderStep()}</div>;

};

const mapDispatchToProps = { toggleLoader,setRegisterUser,setVerifyCode };

const mapStateToProps = ({ Auth }) => {
  let { isLogin, getRegisterUser, getVerifyCode } = Auth;
  return { isLogin, getRegisterUser, getVerifyCode };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
