import React, { useState } from "react";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link , Navigate, useParams} from "react-router-dom";
import moment from "moment";
import { Container, Form, Button } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { CloseOutlined } from '@ant-design/icons'
import { toggleLoader, login, getSingleCase, getCaseHistory, deleteCaseHistory } from "../store/actions/Auth";
import WhatsApp from "./WhatsappShare";
import './UrduForm.css';
const lawyerDiary = "/lawyerdirary.png";

const Signin = (props) => {

  
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");

  const { id } = useParams();

  const submitForm = (e) => {
    e.preventDefault();
    // props.login({email,password})
  }

  React.useEffect(()=>{
    props.getSingleCase({id})
    props.getCaseHistory({caseId:id})
  },[id])
  // let token = localStorage.getItem('token');
  if(!props.isLogin) return <Navigate to="/" />

  
  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const input = document.getElementById('printableArea');
    const options = {
      filename: 'downloaded_page.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().set(options).from(input).save();
  };  

  const deleteSingleCase = (caseId,_id)=>{
    props.toggleLoader({message:"Delete history",status:true});
    props.deleteCaseHistory({caseId,_id});
  }

  return (
    <>
      <section className="wrapper" >
        <div className="container urdu-form">
        <div id="printableArea">
        <div class="row" style={{marginTop:50}}>
        <div class="col-2 offset-md-1">
           <img src={lawyerDiary} width={100}/>
           </div>
           <div class="col-8">
            {props.currentLanguage == "urdu" ? 
            <h2 className="text-center font-tables-headings" style={{marginTop:50, fontSize:40}}> {props.setSingleCase.caseNo ? props.setSingleCase.caseNo : "-"} : کیس نمبر</h2>
            :
            <h2 className="text-center font-tables-headings" style={{marginTop:50, fontSize:40}}> Case No : {props.setSingleCase.caseNo ? props.setSingleCase.caseNo : "-"}</h2>
            }
           </div>
        </div>
         {props.currentLanguage == "urdu" ? 
         <div className="font-tables">
          {/* <Form> */}
          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: موجودہ تاریخ</h4></Form.Label>
              <h5>{moment(props.setSingleCase?.currentDate).format('DD-MM-YYYY')}</h5>
          </div>
          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: مقدمہ/ سال / پولیس اسٹیشن</h4></Form.Label>
              <h5>{props.setSingleCase?.caseYearPolicestation}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: ویب لنک</h4></Form.Label>
              <h5><a href={props.setSingleCase?.caseURL} target="_blank">{props.setSingleCase?.caseURL}</a> </h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: نوعیت/جرم</h4></Form.Label>
              <h5>{props.setSingleCase?.criminal}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: عنوان</h4></Form.Label>
              <h5>{props.setSingleCase?.titleA }  بنام  { props.setSingleCase?.titleB}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: عدالت </h4></Form.Label>
              <h5>{props.setSingleCase?.addalat}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: امروز کاروائی</h4></Form.Label>
              <h5>{props.setSingleCase?.lastAction}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: آئندہ تاریخ پیشی</h4></Form.Label>
              <h5>{moment(props.setSingleCase?.nextDate).format('DD-MM-YYYY')}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: آئندہ کاروائی	 </h4></Form.Label>
              <h5>{props.setSingleCase?.nextAction}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: کلائنٹ کا نام  </h4></Form.Label>
              <h5>{props.setSingleCase?.clientname}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: فون نمبر   </h4></Form.Label>
              <h5>{props.setSingleCase?.phoneno}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>:  کل فیس </h4></Form.Label>
              <h5>{props.setSingleCase?.totalFee}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>: فیس جو دی گی </h4></Form.Label>
              <h5>{props.setSingleCase?.totalPay}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20}}>
              <Form.Label><h4>:فیس بقایا </h4></Form.Label>
              <h5>{props.setSingleCase?.outstandingFee}</h5>
          </div>
          </div>    
          :
          <div className="font-tables">
          {/* <Form> */}
          <div className="underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Current Date :</h4></Form.Label>
              <h5>{moment(props.setSingleCase?.currentDate).format('DD-MM-YYYY')}</h5>
          </div>
          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Case/Police Station :</h4></Form.Label>
              <h5>{props.setSingleCase?.caseYearPolicestation}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Web Link :</h4></Form.Label>
              <h5><a href={props.setSingleCase?.caseURL} target="_blank">{props.setSingleCase?.caseURL}</a> </h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Offence/Case :</h4></Form.Label>
              <h5>{props.setSingleCase?.criminal}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Title :</h4></Form.Label>
              <h5>{props.setSingleCase?.titleA }  VS  { props.setSingleCase?.titleB}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Court Name : </h4></Form.Label>
              <h5>{props.setSingleCase?.addalat}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Today Proceeding :</h4></Form.Label>
              <h5>{props.setSingleCase?.lastAction}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Next Date :</h4></Form.Label>
              <h5>{moment(props.setSingleCase?.nextDate).format('DD-MM-YYYY')}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Next Proceeding : </h4></Form.Label>
              <h5>{props.setSingleCase?.nextAction}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Client Name :</h4></Form.Label>
              <h5>{props.setSingleCase?.clientname}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Phone No :</h4></Form.Label>
              <h5>{props.setSingleCase?.phoneno}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Total Fee :</h4></Form.Label>
              <h5>{props.setSingleCase?.totalFee}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Fees to be paid :</h4></Form.Label>
              <h5>{props.setSingleCase?.totalPay}</h5>
          </div>

          <div className="urdu-form underline" style={{padding:20,textAlign:"left"}}>
              <Form.Label><h4>Remaining Fees :</h4></Form.Label>
              <h5>{props.setSingleCase?.outstandingFee}</h5>
          </div>
          </div>    
          }      
          </div>
          <div className="text-center  non-printable">
                {/* <Button  className="common-btn" style={{margin:10}} onClick={handlePrint} >
                  Print
                </Button> */}
                <Button  className="common-btn" style={{margin:10}} onClick={handlePrint} >
                  Print
                </Button>
                <Button  className="common-btn" style={{margin:10}} onClick={handleDownload} >
                  Download
                </Button>
                <Link  className="common-btn" style={{margin:10}} to={"/editcase/"+props.setSingleCase['_id']} >
                  Edit
                </Link>
                <span style={{border:"1px solid #697ffb",padding:10,borderRadius:10,color:"#697ffb", background:"#697ffb",margin:10}} >
                {props.currentLanguage == "urdu" ? 
                       <WhatsApp 
                       data={{
                              " عدالت": props.setSingleCase?.['addalat'],
                              "آئندہ کاروائی":  props.setSingleCase?.['nextAction'],
                              "آئندہ تاریخ پیشی	":  moment(props.setSingleCase?.nextDate).format('DD-MM-YYYY'),
                              "امروز کاروائی":  props.setSingleCase?.['lastAction'],
                        }} phoneno={props.setSingleCase?.['phoneno']} />
                :
                  <WhatsApp 
                  data={{
                        "Court Name ": props.setSingleCase?.['addalat'],
                        "Next Proceeding ":  props.setSingleCase?.['nextAction'],
                        "Next Date ":  moment(props.setSingleCase?.nextDate).format('DD-MM-YYYY'),
                        "Today Proceeding ":  props.setSingleCase?.['lastAction'],
                  }} phoneno={props.setSingleCase?.['phoneno']} />
                }
                </span>
          </div>
          {/* </Form> */}

        </div>
      </section>


      <div className="main-container-head mb-3  non-printable" id="printableArea">
                <div className="container table-responsive py-5"> 
                {props.currentLanguage == "urdu" ? 
                <table className="table table-bordered table-hover font-tables">
                  <thead className="thead-dark urdu-form ">
                    <tr>
                        <th scope="col">ڈیلیٹ</th>
                        <th scope="col">امروز کاروائی</th>
                        <th scope="col">سابقہ تاریخ پیشی</th>
                        <th scope="col">نمبرشمار</th>
                    </tr>
                  </thead>
                  <tbody className="urdu-form">
                    {props.caseHistory && props.caseHistory.length > 0 && props.caseHistory.map((item,index)=>
                      <tr key={index}>
                        <td onClick={()=>deleteSingleCase(id,item['_id'])}><CloseOutlined style={{color:"red"}} /></td>
                        <td>{item["lastAction"] ? item["lastAction"] : "-"}</td>
                        <td>{item["lastDate"] ? moment(item["lastDate"]).format('DD.MM.YYYY'): "-"}</td>
                        <th scope="row">{index + 1}</th>
                      </tr>
                    )}
                  </tbody>
                </table>
                :
                <table className="table table-bordered table-hover font-tables">
                <thead className="thead-dark  ">
                  <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Previous Date.</th>
                      <th scope="col">Today Proceeding.</th>
                      <th scope="col">Delete.</th>
                  </tr>
                </thead>
                <tbody className="">
                  {props.caseHistory && props.caseHistory.length > 0 && props.caseHistory.map((item,index)=>
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item["lastDate"] ? moment(item["lastDate"]).format('DD.MM.YYYY'): "-"}</td>
                      <td>{item["lastAction"] ? item["lastAction"] : "-"}</td>
                      <td onClick={()=>deleteSingleCase(id,item['_id'])}><CloseOutlined style={{color:"red"}} /></td>
                    </tr>
                  )}
                </tbody>
              </table>
              }
              </div>
              </div>
    </>
  );
};

const mapDispatchToProps = { toggleLoader, login, getSingleCase, getCaseHistory, deleteCaseHistory };

const mapStateToProps = ({ Auth }) => {
  let {isLogin,setSingleCase,caseHistory,currentLanguage  } = Auth;
  return { isLogin, setSingleCase,caseHistory,currentLanguage };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
